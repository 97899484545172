  <div class="flex flex-col space-y-4 md:grid md:grid-cols-2 md:gap-3 sm:space-y-0 " [formGroup]="form">

  <div>
    <label>Nome *</label>
    <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
           formControlName="firstname"
           type="text"/>

  </div>
  <div>
    <label>Cognome *</label>
    <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
           formControlName="lastname"
           type="text"/>
  </div>
  <div>
    <label>Telefono</label>
    <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
           formControlName="mobilePhone"
           type="text"/>
  </div>
  <div>
    <label>Email</label>
    <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
           formControlName="email"
           type="text"/>
  </div>


</div>
