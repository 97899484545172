import {Component, EventEmitter, Input, OnInit, Output, SkipSelf} from '@angular/core';
import {faCheck, faEye, faFileAlt, faShip, faUsers} from "@fortawesome/free-solid-svg-icons";
import {
  AttachmentDirectoryDTO,
  AttachmentType,
  BoatDTO,
  ReservationRequestDTO,
  ShipOwnerDTO,
  SkipperDTO
} from "../../service/dto";
import {FormBuilder, FormGroup} from "@angular/forms";
import {debounceTime, distinctUntilChanged, map, Observable, ReplaySubject, switchMap, tap} from "rxjs";
import {AttachmentDirectoryService} from "../../service/attachment-directory.service";
import {currency} from "../../utils/utils";
import {ExpressionBuilder} from "../../service/ExpressionBuilder";
import {catchError} from "rxjs/operators";
import {BoatService} from "../../service/boat.service";
import {ToastrService} from "ngx-toastr";
import {ShipOwnerService} from "../../service/ship-owner.service";
import {SkipperService} from "../../service/skipper.service";

@Component({
  selector: 'app-reservation-request-form',
  templateUrl: './reservation-request-form.component.html',
  styleUrl: './reservation-request-form.component.scss'
})
export class ReservationRequestFormComponent implements OnInit {
  form: FormGroup;
  @Input()
  request: ReservationRequestDTO;
  dir: Observable<AttachmentDirectoryDTO>
  @Output()
  onSave = new EventEmitter<ReservationRequestDTO>();
  saving = false;
  boats$ = new ReplaySubject<string>()
  boatList: BoatDTO[] = [];
  selectedBoat: BoatDTO;
  selectedSkipper: SkipperDTO;
  selectedOwner: ShipOwnerDTO;

  skippers$ = new ReplaySubject<string>()
  skipperList: SkipperDTO[] = [];

  owners$ = new ReplaySubject<string>()
  ownerList: ShipOwnerDTO[] = [];

  constructor(private fb: FormBuilder,
              private boatService: BoatService,
              private ownerService: ShipOwnerService,
              private skipperService: SkipperService,
              private toastService: ToastrService,
              private attachmentDirectory: AttachmentDirectoryService) {
    this.form = this.fb.group({
      request: undefined,
      boatRequest: undefined,
      skipper: undefined,
      owner: undefined,
      userNote: {value: undefined, disabled: true}
    });


    this.owners$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(x => {
        let exp = ExpressionBuilder.getBuilder().or();
        if (!x)
          return exp

        exp.ilike('firstname', '%' + x + '%').ilike('lastname', '%' + x + '%')
        return exp
      }),
      switchMap(x => this.ownerService.getAll(0, 20, x).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),
          map(x=>{
            x.data.forEach(x=> x['fullname'] = x.firstname+" "+(x.lastname??''));
            return x.data
          }),
          tap(x => this.ownerList = x)
        )
      )
    ).subscribe();

    this.skippers$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(x => {
        let exp = ExpressionBuilder.getBuilder().or();
        if (!x)
          return exp

        exp.ilike('firstname', '%' + x + '%').ilike('lastname', '%' + x + '%')
        return exp
      }),
      switchMap(x => this.skipperService.getAll(0, 20, x).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),
        map(x=>{
          x.data.forEach(x=> x['fullname'] = x.firstname+" "+(x.lastname??''));
          return x.data
        }),
          tap(x => this.skipperList = x)
        )
      )
    ).subscribe();

    this.boats$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(x => {
        let exp = ExpressionBuilder.getBuilder().or();
        if (!x)
          return exp

        exp.ilike('name', '%' + x + '%').ilike('plateNumber', '%' + x + '%')
        return exp
      }),
      switchMap(x => this.boatService.getAll(0, 20, x, {skipper: true}).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),

          tap(x => this.boatList = x.data)
        )
      )
    ).subscribe();

  }

  updateForm(request: ReservationRequestDTO) {
    debugger
    this.form.patchValue({
      request: request,
      boatRequest: request,
      skipper: {
        firstname: request.skipperName,
        lastname: request.skipperSurname,
        email: request.skipperEmail,
        mobilePhone: request.skipperPhone,
      } as SkipperDTO,
      owner: {
        firstname: request.ownerName,
        lastname: request.ownerLastname,
        pec: request.ownerPec,
        phone: request.ownerPhone,
        vatCode: request.ownerVatCode,
        taxCode: request.ownerTaxCode,
        email: request.ownerEmail,
        type: request.ownerType
      } as ShipOwnerDTO,
      userNote: request.userNote
    });
  }

  save() {
    this.saving = true;

    const obj = {
      ...this.request,
      id: this.request.id,
      //boat
      boatId: this.form.getRawValue().boatRequest?.boatId,
      plateNumber: this.form.getRawValue().boatRequest?.plateNumber,
      registryNumber: this.form.getRawValue().boatRequest?.registryNumber,
      boatLength: this.form.getRawValue().boatRequest?.length,
      boatBeam: this.form.getRawValue().boatRequest?.beam,
      boatDraft: this.form.getRawValue().boatRequest?.draft,
      boatName: this.form.getRawValue().boatRequest?.boatName,
      boatType: this.form.getRawValue().boatRequest?.type,
      typeId: this.form.getRawValue().boatRequest?.type?.id,
      flag: this.form.getRawValue().boatRequest?.flag,
      flagId: this.form.getRawValue().boatRequest?.flag?.id,

      //skipper
      skipperId: this.form.getRawValue().skipper?.id,
      skipperName: this.form.getRawValue().skipper?.firstname,
      skipperSurname: this.form.getRawValue().skipper?.lastname,
      skipperEmail: this.form.getRawValue().skipper?.email,
      skipperPhone: this.form.getRawValue().skipper?.mobilePhone,
      //owner
      ownerId: this.form.getRawValue().owner?.id,
      ownerName: this.form.getRawValue().owner?.firstname,
      ownerLastname: this.form.getRawValue().owner?.lastname,
      ownerPec: this.form.getRawValue().owner?.pec,
      ownerPhone: this.form.getRawValue().owner?.phone,
      ownerVatCode: this.form.getRawValue().owner?.vatCode,
      ownerTaxCode: this.form.getRawValue().owner?.taxCode,
      ownerEmail: this.form.getRawValue().owner?.email,
      ownerType: this.form.getRawValue().owner?.type,
      userNote: this.form.getRawValue().userNote,
      status: 'CONFIRMED'
    } as ReservationRequestDTO
    // delete obj.port?.['__typename'];
    // delete obj.reservedBerth?.['__typename'];
    // delete obj.boatType?.['__typename'];
    // delete obj.docking?.['__typename'];
    // delete obj.flag?.['__typename'];
    // delete obj.attachmentDirectory?.['__typename'];

    this.onSave.emit(obj)
  }

  refreshData() {
    this.dir = this.attachmentDirectory.getById(this.request.attachmentDirectoryId)

  }

  ngOnInit(): void {

    this.refreshData();
    this.updateForm(this.request)

  }

  getCurrency() {
    return currency.find(x => x.iso3 == this.request.currencyIso3)?.symbol;
  }

  protected readonly faFileAlt = faFileAlt;
  protected readonly faCheck = faCheck;
  protected readonly AttachmentType = AttachmentType;
  protected readonly faEye = faEye;
  protected readonly faShip = faShip;
  protected readonly faUsers = faUsers;

  onClearBoat() {
    this.form.get('boatRequest').enable()
    this.form.patchValue({
      boatRequest: undefined
    })
    this.form.get('boatRequest').updateValueAndValidity()
    this.selectedBoat = undefined

  }

  updateBoat(boat: BoatDTO) {
    this.selectedBoat = boat
    this.form.patchValue({
      boatRequest: {
        boatId: boat.id,
        plateNumber: boat.plateNumber,
        length: boat.length,
        beam: boat.beam,
        draft: boat.draft,
        boatName: boat.name,
        boatType: boat.type,
        flag: boat.flag,
        registryNumber: boat.registryNumber,
      } as ReservationRequestDTO,

    })
    this.form.get('boatRequest').disable()
    this.form.get('boatRequest').updateValueAndValidity()
    if(this.selectedBoat){
      this.updateSkipper(this.selectedBoat.skipper)
      this.updateOwner(this.selectedBoat.shipOwner)
      this.skipperList = [this.selectedBoat.skipper]
      this.ownerList = [this.selectedBoat.shipOwner]
      //@ts-ignore
      this.selectedOwner =  {...this.selectedBoat?.shipOwner, fullname: this.selectedBoat?.shipOwner.firstname+" "+(this.selectedBoat?.shipOwner.lastname??'')}
      //@ts-ignore
      this.selectedSkipper =  {...this.selectedBoat?.skipper, fullname: this.selectedBoat?.skipper.firstname+" "+this.selectedBoat?.skipper.lastname}
    }else{
      this.onClearSkipper()
      this.onClearOwner()
      this.skipperList = []
      this.ownerList = []
    }
  }


  onClearSkipper() {
    this.form.get('skipper').enable()
    this.form.patchValue({
      skipper: undefined
    })
    this.form.get('skipper').updateValueAndValidity()

  }

  updateSkipper(skipper: SkipperDTO) {
    this.form.patchValue({
      skipper: skipper
    })
    this.form.get('skipper').updateValueAndValidity()

    this.form.get('skipper').disable()
  }

  onClearOwner() {
    this.form.get('owner').enable()
    this.form.patchValue({
      owner: undefined
    })
    this.form.get('owner').updateValueAndValidity()

  }

  updateOwner(owner: ShipOwnerDTO) {
    this.form.patchValue({
      owner: owner
    })
    this.form.updateValueAndValidity()

    this.form.get('owner').disable()
  }


}
