
<div [formGroup]="form" class="space-y-2">

  <div class="flex flex-col space-y-4 md:grid md:grid-cols-3 md:gap-3 sm:space-y-0 lg:grid-cols-4">

    <div>
      <label>Nome barca</label>
      <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
             formControlName="boatName"
             type="text"/>
    </div>
    <div>
      <div class="flex flex-col relative">
        <label>Targa</label>
        <input class="pl-3 pr-10 py-1 text-xs w-full placeholder:text-gray-500 form-control border rounded-lg"
               formControlName="plateNumber" type="text"/>

      </div>
    </div>
    <div>
      <label>Numero registro</label>
      <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control  "
             formControlName="registryNumber"
             type="text"/>

    </div>
    <div>
      <div class="grid grid-cols-3 gap-x-3">

        <div class="col-span-3 mt-[-0.25rem]  text-center">
          <span>Dimensioni barca</span>
        </div>
        <div class=" mt-[-0.5rem]">
          <label class="text-xs">Lung. <span *ngIf="isRequiredField('length')">*</span></label>
          <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control control-small"
                 formControlName="length"
                 min="0"
                 type="number"/>

        </div>
        <div class=" mt-[-0.5rem]">
          <label class="text-xs"> Larg. <span *ngIf="isRequiredField('beam')">*</span></label>
          <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control control-small"
                 formControlName="beam"
                 min="0"
                 type="number"/>

        </div>
        <div class=" mt-[-0.5rem]">
          <label class="text-xs"> Pesc. <span *ngIf="isRequiredField('draft')">*</span></label>
          <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control control-small"
                 formControlName="draft"
                 min="0"
                 type="number"/>

        </div>
      </div>
    </div>
    <div class="relative">
      <div><label>Bandiera</label></div>
      <ng-select (focus)="countries$.next('')"
                 [items]="countryList"
                 [typeahead]="countries$"
                 appendTo="body"
                 bindLabel="name"
                 formControlName="flag">
      </ng-select>
    </div>
    <div class="relative">
      <div><label>Tipologia</label></div>
      <ng-select (focus)="boatTypes$.next('')"
                 [items]="boatTypesList"
                 [typeahead]="boatTypes$"
                 appendTo="body"
                 bindLabel="name"
                 formControlName="type">
      </ng-select>
    </div>


  </div>
</div>
