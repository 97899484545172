@if (request?.status == 'TO_HANDLE') {
  <fa-icon class="block scale-110"  matTooltip="Da gestire" [icon]="faGears"></fa-icon>

} @else if (request?.status == 'CANCELLED') {
  <fa-icon  class="block scale-110" matTooltip="Cancellata" [icon]="faBan"></fa-icon>

} @else if (request?.status == 'DONE') {
  <fa-icon class="block scale-110 text-yellow-600" matTooltip="In attesa dal cliente" [icon]="faHourglassHalf"></fa-icon>

} @else if (request?.status == 'ACCEPTED') {
  <fa-icon class="block scale-110 text-green-600" matTooltip="Proposta accettata" [icon]="faCheckCircle"></fa-icon>

} @else if (request?.status == 'ACCEPTED_DATA_OK') {
  <fa-icon class="block scale-110 text-green-800" matTooltip="Proposta accettata" [icon]="faCheckCircle"></fa-icon>

} @else if (request?.status == 'REFUSED') {
  <fa-icon class="block scale-110 text-red-800" matTooltip="Proposta rifiutata" [icon]="faCircleXmark"></fa-icon>

} @else if (request?.status == 'CONFIRMED') {
  <fa-icon class="block scale-110 text-cyan-800" matTooltip="Proposta conclusa" [icon]="faLock"></fa-icon>

}
