import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {InsuranceDataDTO} from "../../../service/dto";
import {faCalendarAlt, faSave} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {toNgbDate} from "../../../utils/utils";

@Component({
  selector: 'app-boat-insurance-data-modal',
  templateUrl: './boat-insurance-data-modal.component.html',
  styleUrl: './boat-insurance-data-modal.component.scss',

})
export class BoatInsuranceDataModalComponent {
  form: FormGroup;
  @Output()
  save$ = new EventEmitter<InsuranceDataDTO>();
  data: { insurance: InsuranceDataDTO, boatId: number }

  constructor(private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) data: { insurance: InsuranceDataDTO, boatId: number },
              public dialogRef: MatDialogRef<BoatInsuranceDataModalComponent>,) {
    this.data = data;
    this.form = this.fb.group({
      company: undefined,
      insuranceName: undefined,
      expiration: undefined,
      premium: undefined
    });
    this.updateForm(data.insurance)
  }

  updateForm(dto: InsuranceDataDTO){
    if(!dto){
      return
    }

    this.form.patchValue({
      company: dto.companyName,
      insuranceName: dto.insurancePolicyName,
      expiration: dto.expirationDate,
      premium: dto.insurancePremium
    });

  }

  close() {
    this.dialogRef.close(null)
  }

  save() {

    const obj = {
      ...this.data.insurance,
      companyName: this.form.getRawValue().company,
      insurancePremium: this.form.getRawValue().premium,
      insurancePolicyName: this.form.getRawValue().insuranceName,
      expirationDate: moment(this.form.getRawValue().expiration, 'YYYY-MM-DD').toDate(),
      boatId: this.data.boatId,
    } as InsuranceDataDTO
    this.save$.emit(obj)
  }

  protected readonly faSave = faSave;
  protected readonly faCalendarAlt = faCalendarAlt;
}
