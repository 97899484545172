import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import moment from "moment";

@Pipe({
  name: 'localizeDate'
})
export class LocalizeDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {

  }

  transform(value: any, pattern?: string): string | null {
    if(!value)
      return  ''
    const dateFormat = this.translateService.instant('common.moment-date-format') ?? 'YYYY-MM-DD';
    return moment(value).format(dateFormat.toUpperCase())
  }

}
