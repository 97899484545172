  <div class="flex flex-col space-y-4 md:grid md:grid-cols-3 md:gap-3 sm:space-y-0 " [formGroup]="form">

  <div class="relative">
    <div><label for="">Tipo</label></div>
    <ng-select [clearable]="false"
               [items]="[{value:'PERSON', name:'Privato'}, {value:'COMPANY', name:'Società'}]"
               bindLabel="name"
               bindValue="value"
               formControlName="type">
    </ng-select>
  </div>
  <ng-container *ngIf="isPerson(); else societa">
    <div>
      <label>Nome *</label>
      <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
             formControlName="firstname"
             type="text"/>

    </div>
    <div>
      <label>Cognome *</label>
      <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
             formControlName="lastname"
             type="text"/>
    </div>

    <div>
      <label>Cod. Fiscale</label>
      <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
             formControlName="taxCode"
             type="text"/>

    </div>
  </ng-container>

  <div>
    <label>Telefono</label>
    <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
           formControlName="phone"
           type="text"/>
  </div>
  <div>
    <label>Email</label>
    <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
           formControlName="email"
           type="text"/>
  </div>
  <div>
    <label>Pec</label>
    <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
           formControlName="pec"
           type="text"/>
  </div>

  <ng-template #societa>
    <div>
      <label>Ragione sociale *</label>
      <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
             formControlName="firstname"
             type="text"/>

    </div>
    <div>
      <label>P.IVA</label>
      <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
             formControlName="vatCode"
             type="text"/>

    </div>
  </ng-template>

</div>
