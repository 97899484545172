<div class="col-span-2 bg-white rounded-xl shadow p-3" [formGroup]="form">
  <h5>Dati imbarcazione</h5>
  <div class="grid grid-cols-3 gap-3">
    <div>
      <label>Nome barca *</label>
      <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
             formControlName="name"
             type="text"/>

    </div>
    <div>
      <label>Targa</label>
      <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
             formControlName="plateNumber"
             type="text"/>

    </div>
    <div>
      <label>Numero registro</label>
      <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control  "
             formControlName="registryNumber"
             type="text"/>

    </div>

    <div class="relative">
      <div><label>Bandiera</label></div>
      <ng-select [items]="countryList"
                 bindLabel="name"
                 formControlName="flag">
      </ng-select>
    </div>
    <div class="relative">
      <div><label for="">Tipologia *</label></div>
      <ng-select [items]="boatTypesList"
                 bindLabel="name"
                 formControlName="boatType">
      </ng-select>
    </div>
    <div>
      <label>Numero massimo passeggeri</label>
      <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control  "
             formControlName="maxPassengers"
             type="number"/>

    </div>
    <div>
      <div class="grid grid-cols-3 gap-x-3">

        <div class="col-span-3  mt-[-0.25rem] text-sm text-center">
          <span>Dimensioni barca</span>
        </div>
        <div class=" mt-[-0.5rem]">
          <label class="text-xs">Lung. <span *ngIf="isRequiredField('length')">*</span></label>
          <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control control-small"
                 formControlName="length"
                 min="0"
                 type="number"/>

        </div>
        <div class=" mt-[-0.5rem]">
          <label class="text-xs">Larg. <span *ngIf="isRequiredField('beam')">*</span></label>
          <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control control-small"
                 formControlName="beam"
                 min="0"
                 type="number"/>

        </div>
        <div class=" mt-[-0.5rem]">
          <label class="text-xs">Pesc. <span *ngIf="isRequiredField('draft')">*</span></label>
          <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control control-small"
                 formControlName="draft"
                 min="0"
                 type="number"/>

        </div>
      </div>
    </div>

  </div>

</div>

<div class=" bg-white rounded-xl shadow p-3 space-y-4">
  <div
    class=" cursor-pointer rounded-xl border-gray-300 border-[1px] flex flex-col justify-center items-center h-[85%] max-h-[85%]">
    <input type="file" class="hidden" [disabled]="isNew()" #inputFile (change)="onUploadFile($event)">
    @if (!boatImg) {
      <fa-icon class="scale-[6]" [icon]="faImages" (click)="isNew()? null : inputFile.click()"></fa-icon>
    } @else {
      <img [src]="boatImg.url " class="cursor-auto rounded-xl w-full max-h-full h-[275px] object-cover p-1"
           height="100%">
    }
  </div>
  <div class="flex flex-row justify-between items-center">
    <div class="flex space-x-3 px-2 items-center">
      @for (img of boat?.boatImages; track img.id) {
        <div (click)="boatImg = img" [attr.data-selected]="img.id == boatImg.id "
             class="transition size-2 bg-slate-500 cursor-pointer rounded-full data-[selected=true]:bg-slate-700 data-[selected=true]:size-3 "></div>
      }
    </div>
    <div>
      <button
        (click)="upload(inputFile)"
        [attr.data-disabled]="isNew()"
        class="inline-block px-8 py-2 m-0 text-xs font-bold text-center  data-[disabled=false]:text-white uppercase align-middle
            transition-all border-0 rounded-lg cursor-pointer ease-in-out tracking-tight data-[disabled=false]:bg-gradient-to-tl from-blue-500
             to-cyan-500 shadow-soft-md bg-150 bg-x-25 hover:scale-[102%] active:opacity-85 data-[disabled=true]:bg-gray-300 data-[disabled=true]:text-slate-500">
        <fa-icon [icon]="faUpload" class="mr-1"></fa-icon>
        Upload
      </button>
    </div>
  </div>
</div>
