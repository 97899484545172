import {NgModule} from '@angular/core';
import {AsyncPipe, CommonModule, DatePipe, NgIf, NgTemplateOutlet, TitleCasePipe, UpperCasePipe} from '@angular/common';
import {SpinnerComponent} from './spinner/spinner.component';
import {PortCardComponent} from './port-card/port-card.component';
import {RouterLink} from "@angular/router";
import {NgLetDirective} from "./ng-let.directive";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker
} from "@angular/material/datepicker";
import {MatIcon} from "@angular/material/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTooltip} from "@angular/material/tooltip";
import {MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {MatSidenavModule} from "@angular/material/sidenav";
import {FileManagerComponent} from "./file-manager/file-manager.component";
import {DateNavigatorComponent} from './date-navigator/date-navigator.component';
import {PortMapComponent} from './port-map/port-map.component';
import {RemoveFileModalComponent} from './file-manager/remove-file-modal/remove-file-modal.component';
import {LoadButtonDirective} from "../utils/load-button.directive";
import {BoatDataFormComponent} from "./boat-data-form/boat-data-form.component";
import {BoatFormComponent} from "./boat-form/boat-form.component";
import {NgLabelTemplateDirective, NgOptionComponent, NgSelectComponent} from "@ng-select/ng-select";
import {MatStep, MatStepContent, MatStepLabel, MatStepper, MatStepperIcon} from "@angular/material/stepper";
import {SearchInPlaceComponent} from "./search-in-place/search-in-place.component";
import { SwitchLanguageComponent } from './switch-language/switch-language.component';
import {TranslatePipe} from "@ngx-translate/core";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import { TranslatePlaceholderDirective } from './translate-placeholder.directive';
import {NgbInputDatepicker} from "@ng-bootstrap/ng-bootstrap";
import { LocalizeDatePipe } from './localize-date.pipe';
import {RequestFormComponent} from "./request-form/request-form.component";
import { ShipownerFormComponent } from './shipowner-form/shipowner-form.component';
import { SkipperFormComponent } from './skipper-form/skipper-form.component';
import { RequestBoatInfoFormComponent } from './request-boat-info-form/request-boat-info-form.component';
import { RequestExtraDataFormComponent } from './request-extra-data-form/request-extra-data-form.component';
import {ReservationRequestFormComponent} from "./reservation-request-form/reservation-request-form.component";
import { ReservationFormComponent } from './reservation-form/reservation-form.component';
import {NgbDatepickerRangeComponent} from "./ngb-datepicker-range/ngb-datepicker-range.component";
import { RequestStatusIconComponent } from './request-status-icon/request-status-icon.component';

@NgModule({
  declarations: [
    SpinnerComponent,
    PortCardComponent,
    NgLetDirective,
    FileManagerComponent,
    DateNavigatorComponent,
    PortMapComponent,
    RemoveFileModalComponent,
    BoatDataFormComponent,
    BoatFormComponent,
    SwitchLanguageComponent,
    TranslatePlaceholderDirective,
    LocalizeDatePipe,
    RequestFormComponent,
    ShipownerFormComponent,
    SkipperFormComponent,
    RequestBoatInfoFormComponent,
    RequestExtraDataFormComponent,
    ReservationRequestFormComponent,
    ReservationFormComponent,
    ReservationFormComponent,
    RequestStatusIconComponent
  ],
  exports: [
    SpinnerComponent,
    PortCardComponent,
    NgLetDirective,
    FileManagerComponent,
    DateNavigatorComponent,
    ReservationRequestFormComponent,
    PortMapComponent,
    BoatDataFormComponent,
    BoatFormComponent,
    SwitchLanguageComponent,
    LocalizeDatePipe,
    RequestFormComponent,
    ShipownerFormComponent,
    SkipperFormComponent,
    RequestBoatInfoFormComponent,
    ReservationFormComponent,
    RequestStatusIconComponent
  ],
  imports: [
    CommonModule,
    RouterLink,
    MatFormField,
    MatLabel,
    FontAwesomeModule,
    MatDateRangeInput,
    MatDatepickerToggle,
    MatDateRangePicker,
    MatIcon,
    ReactiveFormsModule,
    TitleCasePipe,
    AsyncPipe,
    UpperCasePipe,
    MatTooltip,
    FormsModule,
    DatePipe,
    MatDatepickerInput,
    MatDatepicker,
    MatIconButton,
    MatInput,
    NgTemplateOutlet,
    NgIf,
    FaIconComponent,
    MatSidenavModule,
    LoadButtonDirective,
    NgSelectComponent,
    MatStepper,
    MatStep,
    MatStepLabel,
    SearchInPlaceComponent,
    MatStepperIcon,
    NgLabelTemplateDirective,
    TranslatePipe,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    NgbInputDatepicker,
    NgOptionComponent,
    MatStepContent,
    NgbDatepickerRangeComponent,

  ]
})
export class ComponentsModule {
}
