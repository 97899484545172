import {Apollo, gql, MutationResult} from "apollo-angular";
import {ShipYardDataDTO} from "./dto";
import {Injectable, OnDestroy} from "@angular/core";
import {combineLatest, Observable, Subject} from "rxjs";
import {filter, map, takeUntil} from "rxjs/operators";
import {BoolOperation, ExpressionBuilder} from "./ExpressionBuilder";

const SHIP_YARD_FIELD = gql`
  fragment SHIP_YARD_FIELD on ShipYardDataDTO {
    id
    name
    model
    designer
    buildYear
    registrationDate
    boatId
  }
`;

const add = gql`
  ${SHIP_YARD_FIELD}

  mutation AddShipYard($dto: ShipYardDataInput!) {
    shipYardData_add_one(body: $dto) {
      ...SHIP_YARD_FIELD
    }
  }
`;

// cambiato idboat in id e Int! in ID!
const update = gql`
  ${SHIP_YARD_FIELD}

  mutation UpdateShipYard($id: ID!, $dto: ShipYardDataInput!) {
    shipYardData_update_one(id: $id, body: $dto) {
      ...SHIP_YARD_FIELD
    }
  }
`;

const getByBoatId = gql`
  ${SHIP_YARD_FIELD}

  query GetShipYardByBoatId($where: String!) {
    shipYardData(where: $where) {
      ...SHIP_YARD_FIELD
    }
  }
`;

const getAll = gql`
  ${SHIP_YARD_FIELD}

  query GetAllShipYard($pageNumber: Int, $pageDim: Int, $where: String) {
    shipYardData(pageNum: $pageNumber, pageDim: $pageDim, where: $where, sort: "id,desc") {
      ...SHIP_YARD_FIELD
    }
  }
`;

const getById = gql`
  ${SHIP_YARD_FIELD}

  query GetShipYardById($id: Int!) {
    shipYardData_by_pk(id: $id) {
      ...SHIP_YARD_FIELD

    }
  }`;

const count = gql`
  query CountShipYard($where: String) {
    shipYardData_aggregate(aggregation: { count: { field: "id", distinct: false } }, where: $where) {
      count
    }
  }`;

@Injectable({
  providedIn: 'root'
})
export class ShipYardService implements OnDestroy {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apollo: Apollo) {}

  add(dto: ShipYardDataDTO): Observable<ShipYardDataDTO> {
    console.log("Adding ShipYard:", dto);
    return this.apollo.mutate({
      mutation: add,
      variables: {dto: dto},
      fetchPolicy: 'no-cache',
    }).pipe(map((x: MutationResult) => x.data.shipYardData_add_one));
  }

  // cambiato per usare id invece di idBoat

  update(id: number, dto: ShipYardDataDTO): Observable<ShipYardDataDTO> {
    console.log("Updating ShipYard:", dto);
    return this.apollo.mutate({
      mutation: update,
      variables: {id: id, dto: dto},
      fetchPolicy: 'no-cache',
    }).pipe(map((x: MutationResult) => x.data.shipYardData_update_one));
  }

//   getByBoatId(boatId: number): Observable<ShipYardDataDTO> {
//     console.log(`Fetching shipyard by boat ID: ${boatId}`);
//     return this.apollo.watchQuery({
//       query: getByBoatId,
//       variables: {
//         where: `boatId==${boatId}`,
//       },
//       fetchPolicy: 'no-cache'
//     }).valueChanges.pipe(
//       filter(x => !x.loading),
//       takeUntil(this.destroy$),
//       map(x => {
//         // @ts-ignore
//         const data = x.data?.['shipYardData'];
//         console.log("Shipyard data by boat ID fetched:", data);
//         const d = (({ __typename, ...o }) => o)(data);
//         return d;
//       })
//     );
//   }

  getByBoatId(boatId: number): Observable<ShipYardDataDTO > {
    const rsql = `boatId==${boatId}`;
    // console.log("Fetching shipyard by boat ID with RSQL:", rsql);
    return this.apollo.watchQuery({
      query: getByBoatId,
      variables: {where: rsql},
      fetchPolicy: 'no-cache',
    }).valueChanges.pipe(
      filter(result => !result.loading),
      map(result => {
        // console.log("Shipyard data by boat ID fetched:", result.data['shipYardData']);
        const data = result.data['shipYardData'];
        return data.length ? data[0] : {};
      }),
      takeUntil(this.destroy$)
    );
  }


  getAll(page: number = 0, pageSize: number = 10, filters?: BoolOperation): Observable<{
    data: ShipYardDataDTO[],
    totalRows: number
  }> {

    const fetchData = this.apollo
      .watchQuery({
        query: getAll,
        variables: {
          pageNumber: page,
          pageDim: pageSize,
          where: ExpressionBuilder.toGql(filters),
        },
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));

    const fetchCount = this.apollo
      .watchQuery({
        query: count,
        variables: { where: ExpressionBuilder.toGql(filters) },
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));

    return combineLatest([fetchData, fetchCount]).pipe(
      takeUntil(this.destroy$),
      filter(x => !!x[0].data),
      map(x => {
        // @ts-ignore
        const aggRes = x[1].data['shipYardData_aggregate'];
        // @ts-ignore
        const data = x[0].data?.['shipYardData'];
        const d = data.map((y: any) => (({ __typename, ...o }) => o)(y));
        return {
          data: d,
          totalRows: aggRes.count
        };
      })
    );
  }

  getById(id: number): Observable<ShipYardDataDTO> {
    return this.apollo.watchQuery({
      query: getById,
      variables: {
        id: id,
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      filter(x => !x.loading),
      takeUntil(this.destroy$),
      map(x => {
        // @ts-ignore
        const data = x.data?.['shipYardData_by_pk'];
        const d = (({ __typename, ...o }) => o)(data);
        return d;
      }
    )
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
