<div class="p-4 space-y-3 text-sm">
  <h5>{{ data.insurance ? 'Dettagli polizza' : 'Nuova polizza' }}</h5>
  <div class="grid grid-cols-2 gap-3" [formGroup]="form">
    <div>
      <label>Compagnia</label>
      <input class="pl-3  text-sm  w-full placeholder:text-gray-500 form-control"
             formControlName="company"
             type="text"/>

    </div>
    <div>
      <label>Nome polizza</label>
      <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
             formControlName="insuranceName"
             type="text"/>

    </div>
    <div>
      <label>Massimale</label>
      <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control"
             formControlName="premium"
             type="text"/>

    </div>
    <div class="flex flex-col relative">
      <label>Scadenza</label>

      <div class="input-group">
        <input
          class="form-control"
          [placeholder]="'common.date-format' | translate"
          formControlName="expiration"
          ngbDatepicker
          container="body"
          #d="ngbDatepicker"
        />
        <button class="btn btn-outline-secondary " (click)="d.toggle()">
          <fa-icon [icon]="faCalendarAlt"></fa-icon>
        </button>
      </div>
    </div>
    <!--    <div class="relative">-->
    <!--      <label>Scadenza</label>-->
    <!--      <div class="input-group">-->
    <!--        <input-->
    <!--          class="formData-control pr-4"-->
    <!--          placeholder="yyyy-mm-dd"-->
    <!--          name="dp"-->
    <!--          formControlName="expiration"-->
    <!--          ngbDatepicker-->
    <!--          [minDate]="minDate"-->
    <!--          container="body"-->
    <!--          #d="ngbDatepicker"-->
    <!--        />-->
    <!--        <fa-icon [icon]="faCalendarAlt" (click)="d.toggle()" class="cursor-pointer absolute z-10 right-4 top-2"></fa-icon>-->

    <!--        &lt;!&ndash;        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>&ndash;&gt;-->
    <!--      </div>-->
    <!--    </div>-->


  </div>
  <div class="grid grid-cols-2 gap-3">
    <button class="btn btn-light cursor-pointer" (click)="close()">
        <span class="mat-button-wrapper">
          Indietro
        </span>

    </button>

    <button class="btn btn-primary flex cursor-pointer"
            (click)="save()" [disabled]="!form.valid">
      <fa-icon [icon]="faSave" class="mr-2  "></fa-icon>
      Salva

    </button>
  </div>

</div>
