<ng-container [formGroup]="form">
  <mat-stepper #stepper linear="true" [disableRipple]="true" class="text-sm">
    <mat-step #step1 [completed]="form.get('request').valid">
      <div>
        <ng-template matStepLabel>
          <div class="flex  items-center">
            <div>Richiesta</div>
          </div>
        </ng-template>
        <div class="mt-4">
          <h6 class="font-bold">Informazioni prenotazione</h6>
        </div>
        <app-request-form formControlName="request"></app-request-form>
        <div class="mt-4">
          <div>
            <h6 class="font-bold">Note del cliente</h6>
            <div class="text-sm">Note che l'utente ha inserito in fase di conferma della richiesta</div>
          </div>

          <textarea formControlName="userNote"
                    class="form-control !text-sm placeholder:text-gray-500 "
                    placeholder="Note dell'utente" rows="3">

              </textarea>

        </div>
        <div class="flex justify-end mt-4">
          <button (click)="stepper.next()" [disabled]="!step1.completed" class="btn btn-primary">Successivo</button>
        </div>
      </div>
    </mat-step>
    <mat-step #step2 [completed]="form.get('boatRequest').disabled || form.get('boatRequest').valid ">
      <div>
        <ng-template matStepLabel>
          <div class="flex  items-center">
            <div>Barca</div>
          </div>
        </ng-template>
        <ng-template matStepContent>

          <div class="flex items-center mt-4 space-x-4">
            <h6 class="font-bold">Dati imbarcazione</h6>
            <app-search-in-place class="flex-grow"
                                 bindLabel="name"
                                 tooltip="Ricerca in anagrafica"
                                 [searchObs]="boats$"
                                 [data]="boatList"
                                 [open]="false"
                                 (onClear)="onClearBoat()"
                                 placeholder="Associa barca"
                                 (onUpdateData)="updateBoat($event)">
            </app-search-in-place>
          </div>
          <app-request-boat-info-form formControlName="boatRequest"></app-request-boat-info-form>


          <div class="flex justify-between mt-4">
            <button (click)="stepper.previous()" class="btn btn-light">Precedente</button>

            <button (click)="stepper.next()" [disabled]="!step2.completed" class="btn btn-primary">Successivo</button>
          </div>
        </ng-template>
      </div>
    </mat-step>

    <mat-step #step3 [completed]="(form.get('skipper').valid || form.get('skipper').disabled)  && (form.get('owner').valid || form.get('owner').disabled)">
      <div>
        <ng-template matStepLabel>
          <div class="flex  items-center">
            <div>Anagrafiche</div>
          </div>
        </ng-template>
        <ng-template matStepContent>

          <div class="flex items-center mt-4 space-x-4">
            <h6 class="font-bold">Dati proprietario</h6>
            <app-search-in-place class="flex-grow"
                                 bindLabel="fullname"
                                 tooltip="Ricerca in anagrafica"
                                 [searchObs]="owners$"
                                 [bindLabel]=""
                                 [data]="ownerList"
                                 [open]="!!this.selectedBoat?.id"
                                 [selectedData]="selectedOwner"
                                 (onClear)="onClearOwner()"
                                 placeholder="Ricerca proprietario"
                                 (onUpdateData)="updateOwner($event)">
            </app-search-in-place>
          </div>
          <app-shipowner-form formControlName="owner"></app-shipowner-form>

          <div class="mt-2 flex items-center mt-4 space-x-4">
            <h6 class="font-bold">Dati Comandante</h6>
            <app-search-in-place class="flex-grow"
                                 bindLabel="fullname"
                                 tooltip="Ricerca in anagrafica"
                                 [searchObs]="skippers$"
                                 [data]="skipperList"
                                 [open]="!!this.selectedBoat?.id"
                                 [selectedData]="selectedSkipper"
                                 (onClear)="onClearSkipper()"
                                 placeholder="Ricerca comandante"
                                 (onUpdateData)="updateSkipper($event)">
            </app-search-in-place>
          </div>
          <app-skipper-form formControlName="skipper"></app-skipper-form>

          <div class="flex justify-between mt-4">
            <button (click)="stepper.previous()" class="btn btn-light">Precedente</button>

            <button (click)="stepper.next()" [disabled]="!step3.completed" class="btn btn-primary">Successivo</button>
          </div>
        </ng-template>
      </div>
    </mat-step>
    <mat-step #step4 completed="true">
      <div>
        <ng-template matStepLabel>
          <div class="flex  items-center">
            <div>Documenti</div>
          </div>
        </ng-template>
        <ng-template matStepContent>

          <div class="mt-4">
            <h6 class="font-bold">Documenti caricati</h6>
          </div>

          <app-file-manager *ngIf="dir | async as r"
                            [rootDir]="r "
                            [docExpiring]="false"
                            [mode]="'FLAT'"
                            [externalId]="request.id"
                            [readonly]="false"
                            [type]="AttachmentType.RESERVATION_REQ_DOC">
          </app-file-manager>

          <div class="flex justify-between mt-4">
            <button (click)="stepper.previous()" class="btn btn-light">Precedente</button>

            <button (click)="stepper.next()" [disabled]="!step4.completed" class="btn btn-primary">Successivo
            </button>
          </div>
        </ng-template>
      </div>

    </mat-step>
    <mat-step>
      <div class="h-full">
        <ng-template matStepLabel>
          <div class="flex  items-center">
            <div>Conferma</div>
          </div>
        </ng-template>
        <div class="mt-8">
          Confermando salverai le modifiche alla richiesta di prenotazione, e confermerai la prenotazione: <br>
          <ul class="mt-4">
            <li><b>Porto:</b> {{ request.port.name| titlecase }}</li>
            <li><b>Posto barca:</b> {{ request.reservedBerth?.code }}</li>
            <li><b>Date:</b> dal {{ request.arrivalDate| localizeDate }} al {{ request.departureDate | localizeDate }}</li>
            <li><b>Prezzo:</b> {{ request.price }} {{ getCurrency() }}</li>
          </ul>
          Procedere?
        </div>
        <div class="flex justify-between mt-4">
          <button (click)="stepper.previous()" class="btn btn-light">Precedente</button>

          <button (click)="save()" [loading]="saving" [disabled]="!form.valid" class="btn btn-success">Si, prenota</button>
        </div>
      </div>
    </mat-step>

    <ng-template matStepperIcon="number" let-index="index">
      <ng-container *ngTemplateOutlet="icons; context:{index: index}"></ng-container>

    </ng-template>
    <ng-template matStepperIcon="edit" let-index="index">
      <ng-container *ngTemplateOutlet="icons; context:{index: index}"></ng-container>
    </ng-template>
    <ng-template #icons let-index="index">
      @if (index == 0) {
        <fa-icon [icon]="faEye" class="scale-75"></fa-icon>
      } @else if (index == 1) {
        <fa-icon [icon]="faShip" class="scale-75"></fa-icon>
      } @else if (index == 2) {
        <fa-icon [icon]="faUsers" class="scale-75"></fa-icon>
      } @else if (index == 3) {
        <fa-icon [icon]="faFileAlt" class="scale-75"></fa-icon>
      } @else if (index == 4) {
        <fa-icon [icon]="faCheck" class="scale-75"></fa-icon>
      }
    </ng-template>
  </mat-stepper>
</ng-container>
