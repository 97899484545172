<ng-container [formGroup]="form">
  <ng-container *ngTemplateOutlet="anagrafica"></ng-container>
</ng-container>

<ng-template #anagrafica>
  <div class="flex justify-end mb-2">
    <ng-container
      *ngLet="!form.valid || (!this.shipOwnershipSelected && !this.ownerForm.valid)  as check">
      <button
        (click)="save()"
        [loading]="loading$|async"
        [disabled]="check"
        [attr.data-disabled]="check"
        class="inline-block px-8 py-2 m-0 text-xs font-bold text-center  data-[disabled=false]:text-white uppercase align-middle transition-all border-0
       rounded-lg cursor-pointer ease-in-out tracking-tight data-[disabled=false]:bg-gradient-to-tl data-[disabled=false]:from-blue-500  data-[disabled=false]:to-cyan-500 shadow-soft-md bg-150
        bg-x-25 hover:scale-[102%]  data-[disabled=true]:bg-gray-300 data-[disabled=true]:text-slate-500">
        <fa-icon [icon]="faSave" class="mr-1  "></fa-icon>
        Salva
      </button>
    </ng-container>
  </div>

  <div class="grid grid-cols-3 gap-3">

    <div class="col-span-3" [formGroup]="form">
      <app-boat-data-form formControlName="boat"
                          class=" grid grid-cols-3 gap-3"
                          [boat]="boat"
                          [boatTypesList]="boatTypesList" [countryList]="countryList"
                          (onFileUpdated)="onFileUpdated($event)"
                          (onFormUpdated)="onBoatFormUpdated($event)"></app-boat-data-form>
    </div>

    <div [formGroup]="ownerForm" class="col-span-2  bg-white rounded-xl shadow p-3">
      <div class="space-x-4 flex items-center">
        <h5>Dati Proprietario</h5>
        <!--        <app-search-inplace [searchObs]="shipOwner$" [open]="false" fieldLabel="Ricerca proprietario" (onUpdateData)="updateOwner()"></app-search-inplace>-->
        <app-search-in-place class="flex-grow"
                             bindLabel="fullName"
                             tooltip="Ricerca in anagrafica"
                             [searchObs]="shipOwner$"
                             [data]="shipOwnerList"
                             [open]="false"
                             (onClear)="onClearOwner()"
                             placeholder="Associa proprietario"
                             (onUpdateData)="updateOwner($event)">
        </app-search-in-place>
      </div>
      <app-shipowner-form formControlName="owner"></app-shipowner-form>
    </div>
    <div class="bg-white rounded-xl shadow p-2">

      <mat-stepper #stepper [disableRipple]="true">
        <mat-step>
          <div>
            <ng-template matStepLabel>
              <div class="flex  items-center">
                <div>Assicurazioni</div>

              </div>
            </ng-template>
            <ng-container *ngTemplateOutlet="insuranceDataBox"></ng-container>


          </div>
        </mat-step>


        <mat-step>
          <div>
            <ng-template matStepLabel>
              <div class="flex  items-center">
                <div>Cantiere</div>

              </div>
            </ng-template>
            <ng-container *ngTemplateOutlet="cantiereDataBox"></ng-container>


          </div>
        </mat-step>

        <mat-step [completed]="isMotorBoat()" [editable]="isMotorBoat()">
          <div>
            <ng-template matStepLabel>
              <div class="flex items-center">
                <div>Motore</div>
              </div>
            </ng-template>
            <div>
              <ng-container *ngTemplateOutlet="propulsioneDataBox"></ng-container>
            </div>
          </div>
        </mat-step>


        <!-- <ng-template matStepperIcon="number" let-index="index">
          @if (index == 0) {
            <fa-icon [icon]="faWallet" class="scale-75"></fa-icon>
          } @else if (index == 1) {
            <fa-icon [icon]="faWallet" class="scale-75"></fa-icon>
          }
        </ng-template>
        <ng-template matStepperIcon="edit" let-index="index">
          @if (index == 0) {
            <fa-icon [icon]="faWallet" class="scale-75"></fa-icon>
          } @else if (index == 1) {
            <fa-icon [icon]="faWallet" class="scale-75"></fa-icon>
          }
        </ng-template> -->
        <ng-template matStepperIcon="number" let-index="index">
          @if (index == 0) {
            <fa-icon [icon]="faFileContract" class="scale-75"></fa-icon>
          } @else if (index == 1) {
            <fa-icon [icon]="faShip" class="scale-75"></fa-icon>
          } @else if (index == 2) {
            <fa-icon [icon]="faCogs" class="scale-75"></fa-icon>
          }
        </ng-template>
        <ng-template matStepperIcon="edit" let-index="index">
          @if (index == 0) {
            <fa-icon [icon]="faFileContract" class="scale-75"></fa-icon>
          } @else if (index == 1) {
            <fa-icon [icon]="faShip" class="scale-75"></fa-icon>
          } @else if (index == 2) {
            <fa-icon [icon]="faCogs" class="scale-75"></fa-icon>
          }
        </ng-template>
      </mat-stepper>

    </div>

    <div [formGroup]="skipperForm" class="col-span-2  bg-white rounded-xl shadow p-3">
      <div class="space-x-4 flex items-center">
        <h5>Dati Comandante</h5>
        <!--        <app-search-inplace [searchObs]="shipOwner$" [open]="false" fieldLabel="Ricerca proprietario" (onUpdateData)="updateOwner()"></app-search-inplace>-->
        <app-search-in-place class="flex-grow"
                             bindLabel="fullName"
                             tooltip="Ricerca in anagrafica comandanti"
                             [searchObs]="skipper$"
                             [data]="skipperList"
                             [open]="false"
                             (onClear)="onClearSkipper()"
                             placeholder="Associa comandante"
                             (onUpdateData)="updateSkipper($event)"></app-search-in-place>
      </div>
      <app-skipper-form formControlName="skipper"></app-skipper-form>
    </div>
  </div>
</ng-template>


<ng-template #insuranceDataBox>
  <div class="mt-2">

    <div class="flex justify-end">
      <button
        (click)="openInsuranceModal()"
        [attr.data-disabled]="isNew()"
        [disabled]="isNew()"
        class="inline-block px-3 py-1 m-0 text-xs font-bold text-center  data-[disabled=false]:text-blue-500 uppercase align-middle
            transition-all border-[1px] rounded-lg cursor-pointer ease-in-out  bg-transparent border-blue-600 data-[disabled=true]:border-gray-400
             shadow-soft-md bg-150 hover:scale-[102%]  data-[disabled=true]:bg-gray-300 data-[disabled=true]:text-slate-500">
        <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>
        Nuovo
      </button>
    </div>

    <div class="custom-grid custom-insurance-grid text-xs">

      <div class="titleGroup">
        <div><span class="font-bold	">Compagnia</span></div>
        <div><span class="font-bold	">Assicurazione</span></div>
        <div><span class="font-bold	">Scadenza</span></div>
        <div><span class="font-bold	">Azioni</span></div>
      </div>
      @for (i of this.insuranceData|async; track i.id) {
        <div class="rowGroup">
          <div class="item-underline">{{ i.companyName|titlecase }}</div>
          <div class="item-underline">
            {{ i.insurancePolicyName|titlecase }}
          </div>
          <div class="item-underline">
            <span class="bg-green-700 data-[expired=true]:bg-orange-700  text-white rounded-lg px-2 py-1"
                  [attr.data-expired]="moment(i.expirationDate).isBefore(moment(now))">{{ i.expirationDate | localizeDate }}</span>
          </div>

          <div class="item-underline">
            <button mat-icon-button (click)="openInsuranceModal(i)">
              <mat-icon svgIcon="feather:edit"
                        class="mat-icon notranslate icon-size-5 mr-2 mat-icon-no-color"></mat-icon>
            </button>
          </div>
        </div>
      }


    </div>
    <!--    <mat-paginator class="flex justify-center" [length]="totalElements" [pageSize]="pageSize"-->
    <!--                   [pageSizeOptions]="[5, 10, 25, 50]" [pageIndex]="this.page" (page)="onChangePage($event)"-->
    <!--                   aria-label="Select page">-->
    <!--    </mat-paginator>-->
  </div>
</ng-template>

<ng-template #cantiereDataBox>
  <div class="mt-2">
    <div class="flex justify-end">
      <button
        (click)="saveShipYard()"
        [attr.data-disabled]="(cantiereForm.untouched || !cantiereForm.dirty) || !cantiereForm.valid || isNew()"
        [disabled]="(cantiereForm.untouched || !cantiereForm.dirty) ||!cantiereForm.valid ||isNew()"
        class="inline-block px-3 py-1 m-0 text-xs font-bold text-center data-[disabled=false]:text-blue-500 uppercase align-middle
            transition-all border-[1px] rounded-lg cursor-pointer ease-in-out bg-transparent border-blue-600 data-[disabled=true]:border-gray-400
            shadow-soft-md bg-150 hover:scale-[102%] data-[disabled=true]:bg-gray-300 data-[disabled=true]:text-slate-500">
        <fa-icon [icon]="faSave" class="mr-1"></fa-icon>
        Salva
      </button>
    </div>

    <div [formGroup]="cantiereForm" class="flex justify-center">
      <div class="mt-4 w-full max-w-screen-lg">
        <div class="grid grid-cols-1 gap-2">
          <!-- Prima fila -->
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div class="flex flex-col">
              <label class="">Cantiere</label>
              <input class="pl-3 py-1 text-xs w-full placeholder:text-gray-500 form-control border rounded-lg"
                     formControlName="cantiereName" type="text"/>
            </div>
            <div class="flex flex-col">
              <label class="">Modello</label>
              <input class="pl-3 py-1 text-xs w-full placeholder:text-gray-500 form-control border rounded-lg"
                     formControlName="cantiereModel" type="text"/>
            </div>
            <div class="flex flex-col">
              <label class="">Progettista</label>
              <input class="pl-3 py-1 text-xs w-full placeholder:text-gray-500 form-control border rounded-lg"
                     formControlName="cantiereDesigner" type="text"/>
            </div>
          </div>
          <!-- Seconda fila -->
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
            <div class="flex flex-col relative">
              <label class="">Anno costruzione</label>
              <div class="input-group">
                <input
                  class="form-control"
                  [placeholder]="'common.date-format' | translate"
                  name="dp"
                  ngbDatepicker
                  container="body"
                  formControlName="cantiereBuildYear"
                  #d2="ngbDatepicker"
                />
                <button class="btn btn-outline-secondary " (click)="d2.toggle()">
                  <fa-icon [icon]="faCalendarAlt"></fa-icon>
                </button>
              </div>
            </div>
            <div class="flex flex-col relative">
              <label class="">Immatricolazione</label>
              <div class="input-group">
                <input
                  class="form-control"
                  [placeholder]="'common.date-format' | translate"
                  name="dp"
                  formControlName="cantiereRegistrationDate"
                  ngbDatepicker
                  container="body"
                  #d="ngbDatepicker"
                />
                <button class="btn btn-outline-secondary " (click)="d.toggle()">
                  <fa-icon [icon]="faCalendarAlt"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #propulsioneDataBox>
  <div class="mt-2">
    <div class="flex justify-between">
      <div>
        @if (!isMotorBoat()) {
          <span
            class="text-orange-600 text-xs">Scheda motore non disponibile per tipologia <b>{{ form.getRawValue().boat?.boatType?.name|titlecase }}</b></span>
        } @else {
          &nbsp;
        }
      </div>
      <button
        (click)="savePropulsion()"
        [attr.data-disabled]="!isMotorBoat()"
        [disabled]="!isMotorBoat()"
        [ngClass]="{'text-blue-500 border-blue-600 hover:scale-[102%]': isMotorBoat(), 'bg-gray-300 text-slate-500 border-gray-400': !isMotorBoat()}"
        class="inline-block px-3 py-1 m-0 text-xs font-bold text-center uppercase align-middle
              transition-all border-[1px] rounded-lg cursor-pointer ease-in-out bg-transparent shadow-soft-md bg-150">
        <fa-icon [icon]="faSave" class="mr-1"></fa-icon>
        Salva
      </button>
    </div>

    <div [formGroup]="propulsioneForm" class="flex justify-center">
      <div class="mt-4 w-full max-w-screen-lg">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
          <div class="flex flex-col">
            <label class="   ">Modello Motore</label>
            <input class="pl-3 py-1 text-xs w-full placeholder:text-gray-500 form-control border rounded-lg"
                   formControlName="modelloMotore" type="text"/>
          </div>
          <div class="flex flex-col">
            <label class="   ">Marca Motore</label>
            <input class="pl-3 py-1 text-xs w-full placeholder:text-gray-500 form-control border rounded-lg"
                   formControlName="marcaMotore" type="text"/>
          </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-2 mt-4">
          <div class="flex flex-col">
            <label class="   ">Matricola</label>
            <input class="pl-3 py-1 text-xs w-full placeholder:text-gray-500 form-control border rounded-lg"
                   formControlName="matricolaMotore" type="text"/>
          </div>
          <div class="flex flex-col">
            <label class="   ">Potenza CV</label>
            <input class="pl-3 py-1 text-xs w-full placeholder:text-gray-500 form-control border rounded-lg"
                   formControlName="potenzaMaxCV" type="number"/>
          </div>
          <div class="flex flex-col">
            <label class="  ">Potenza kW</label>
            <input class="pl-3 py-1 text-xs w-full placeholder:text-gray-500 form-control border rounded-lg"
                   formControlName="potenzaMaxKW" type="number"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #waiting>
  <div class="flex justify-center items-center w-full h-full">
    <app-spinner size="XL"></app-spinner>
  </div>
</ng-template>

