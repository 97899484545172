import {Injectable, OnDestroy} from '@angular/core';
import {map, Observable, Subject} from "rxjs";
import {Apollo, gql, MutationResult} from "apollo-angular";
import {BoatEngineDTO} from "./dto";

export const BOAT_ENGINE_FIELD = gql`
  fragment BOAT_ENGINE_FIELD on BoatEngineDTO {
    id
    boatId
    brand
    model
    cv
    kw
    propulsionType
    createdAt
    updatedAt
  }
`;

const updateEngine = gql`
${BOAT_ENGINE_FIELD}

mutation UpdateBoatEngine($id: ID!, $dto: BoatEngineInput!) {
  boatEngine_update_one(id: $id, body: $dto) {
    ...BOAT_ENGINE_FIELD
  }
}
`;

const addEngine = gql`
${BOAT_ENGINE_FIELD}

mutation AddBoatEngine($dto: BoatEngineInput!) {
  boatEngine_add_one(body: $dto) {
    ...BOAT_ENGINE_FIELD
  }
}
`;

const deleteEngine = gql`
mutation DeleteBoatEngine($id: ID!) {
  boatEngine_delete_one(id: $id) {
    id
  }
}
`;

@Injectable({
  providedIn: 'root'
})
export class BoatEngineService implements OnDestroy {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apollo: Apollo) { }

  updateEngine(id: number, dto: BoatEngineDTO): Observable<BoatEngineDTO> {
    return this.apollo.mutate({
      mutation: updateEngine,
      variables: { id, dto },
      fetchPolicy: 'no-cache'
    }).pipe(map((result: MutationResult) => result.data.boatEngine_update_one));
  }

  addEngine(dto: BoatEngineDTO): Observable<BoatEngineDTO> {
    return this.apollo.mutate({
      mutation: addEngine,
      variables: { dto },
      fetchPolicy: 'no-cache'
    }).pipe(map((result: MutationResult) => result.data.boatEngine_add_one));
  }

  deleteEngine(id: number): Observable<void> {
    return this.apollo.mutate({
      mutation: deleteEngine,
      variables: { id },
      fetchPolicy: 'no-cache'
    }).pipe(map((result: MutationResult) => {
      if (result.data.boatEngine_delete_one && result.data.boatEngine_delete_one.id) {
        return;
      } else {
        throw new Error('Errore durante la cancellazione del motore');
      }
    }));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
