import {Component, forwardRef} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map, ReplaySubject, switchMap, tap} from "rxjs";
import {ExpressionBuilder} from "../../service/ExpressionBuilder";
import {catchError} from "rxjs/operators";
import {BerthDTO, BoatTypeDTO, DockingTypeDTO, PortDTO, ReservationRequestDTO} from "../../service/dto";
import {BoatTypeService} from "../../service/boat-type.service";
import {PortService} from "../../service/port.service";
import {DockingTypeService} from "../../service/docking-type.service";
import {ToastrService} from "ngx-toastr";
import {currency} from "../../utils/utils";
import moment from "moment/moment";
import {BerthService} from "../../service/berth.service";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {faCalendarAlt, faSave} from "@fortawesome/free-solid-svg-icons";
import {ConfigService} from "../../service/config.service";

type ChangeCallbackFn<T> = (value: T) => void;
type TouchCallbackFn = () => void;

@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
  styleUrl: './request-form.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RequestFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RequestFormComponent),
      multi: true
    }
  ]
})
export class RequestFormComponent implements ControlValueAccessor, Validator {
  form: FormGroup
  boatTypes$ = new ReplaySubject<string>()
  boatTypesList: BoatTypeDTO[] = []

  port$ = new ReplaySubject<string>()
  portList: PortDTO[] = []

  berths$ = new ReplaySubject<string>()
  berthList: BerthDTO[] = []

  docking$ = new ReplaySubject<string>()
  dockingList: DockingTypeDTO[] = []

  reservationRequest: ReservationRequestDTO;
  minDate = {year: moment().year(), month: 1, day: 1} as NgbDate
  maxDate = {year: moment().add(5, 'year').year(), month: 12, day: 31} as NgbDate
  onTouched: () => void = () => {
  };


  constructor(private fb: FormBuilder,
              private boatTypeService: BoatTypeService,
              private portService: PortService,
              private berthService: BerthService,
              private dockingTypeService: DockingTypeService,
              private configService: ConfigService,
              private toastService: ToastrService,) {
    this.form = this.fb.group({
      createdAt: {value: new Date(), disabled: true},
      transit: false,
      docking: undefined,
      arrivalDate: [undefined, [Validators.required, this.arrivalDateValidator('departureDate')]],
      departureDate: [undefined, this.departureDateValidator('arrivalDate')],
      berthCode: {value: undefined, disabled: true},
      port: {value: this.configService.selectedPort, disabled: true},
      length: [undefined, Validators.required],
      beam: [undefined, Validators.required],
      draft: undefined,
      price: [{value: undefined, disabled: true}],
      currencyIso3: [{value: undefined, disabled: true}]
    });
    this.form.get('arrivalDate').valueChanges.subscribe(x=>{
      const departureDateControl = this.form.get('departureDate');
      const arrivalDateControl = this.form.get('arrivalDate').value;
      const transit = this.form.getRawValue().transit;
      if(transit && arrivalDateControl){
        departureDateControl.setValue(moment(arrivalDateControl).endOf('day').toDate())
      }
    });

    this.form.get('transit').valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe(val => {
      const control = this.form.get('departureDate');
      if (val) {
        control.removeValidators(Validators.required)
        const arrivalDate = this.form.getRawValue().arrivalDate;
        if(arrivalDate){
          control.setValue(moment(arrivalDate).endOf('day').toDate())
        }
        control.disable()

      } else {
        control.addValidators(Validators.required)
        control.enable()

      }
      control.updateValueAndValidity()
    });

    this.boatTypes$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(x => {
        const exp = ExpressionBuilder.getBuilder().root();
        if (!x)
          return exp
        exp.ilike('name', '%' + x + '%')
        return exp
      }),
      switchMap(x => this.boatTypeService.getAll(0, 1000, x).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),
          map(x => x.data),
          tap(x => this.boatTypesList = x)
        )
      )
    ).subscribe();

    this.port$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(x => {
        const exp = ExpressionBuilder.getBuilder().root();
        if (!x)
          return exp
        exp.ilike('name', '%' + x + '%')
        return exp
      }),
      switchMap(x => this.portService.getAll(0, 1000, x).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),
          map(x => x.data),
          tap(x => this.portList = x)
        )
      )
    ).subscribe();

    this.berths$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(x => {
        const exp = ExpressionBuilder.getBuilder().root();
        if (!x)
          return exp
        exp.ilike('code', '%' + x + '%')
        return exp
      }),
      switchMap(x => this.berthService.getAll(0, 1000, x).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),
          map(x => x.data),
          tap(x => this.berthList = x)
        )
      )
    ).subscribe();


    this.docking$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(x => {
        const exp = ExpressionBuilder.getBuilder().root();
        if (!x)
          return exp
        exp.ilike('name', '%' + x + '%')
        return exp
      }),
      switchMap(x => this.dockingTypeService.getAll(0, 1000, x).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),
          map(x => x.data),
          tap(x => this.dockingList = x)
        )
      )
    ).subscribe();

  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }
    return {invalidForm: {valid: false, message: 'request not valid'}};
  }

  writeValue(request: ReservationRequestDTO): void {
    this.reservationRequest = request;
    if (request) {

      this.form.patchValue(
        {
          createdAt: request.createdAt,
          transit: request.transit,
          docking: request.docking,
          arrivalDate: request.arrivalDate ,
          departureDate: request.departureDate,
          nextHarbour: request.nextHarbour,
          lastHarbour: request.lastHarbour,
          cashDeposit: request.cashDeposit,
          length: Number(request.length),
          beam: request.beam,
          draft: request.draft,
          boatName: request.boatName,
          type: request.boatType,
          port: request.port,
          plateNumber: request.plateNumber,
          berthCode: request.reservedBerth?.code,
          depositCurrency: request.depositCurrency ?? 'EUR',
          price: request.price,
          currencyIso3: request.currencyIso3,
        });

      if (this.reservationRequest.transit) {
        this.form.get('departureDate').disable()

      } else {
        this.form.get('departureDate').enable()

      }
    }
  }

  registerOnChange(fn: ChangeCallbackFn<object>): void {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: TouchCallbackFn): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
      if(this.reservationRequest?.transit){
        const control = this.form.get('departureDate');
        control.setValue(moment(this.reservationRequest?.arrivalDate).endOf('day').toDate())
        control.disable();

      }
      this.form.get('createdAt')?.disable();

    }
  }


  protected readonly currency = currency;

  getCurrency() {
    return currency.find(x => x.iso3 == this.form.getRawValue().currencyIso3)?.symbol;
  }

  protected readonly Validators = Validators;
  protected readonly faSave = faSave;
  protected readonly faCalendarAlt = faCalendarAlt;

  departureDateValidator(arrivalDateControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const formGroup = control.parent;
      if (!formGroup) {
        return null;
      }
      const arrivalDate = formGroup.get(arrivalDateControlName)?.value;
      const departureDate = control.value;
      if (arrivalDate && departureDate && new Date(departureDate) <= new Date(arrivalDate)) {
        return {'departureDateInvalid': true};
      }
      return null;
    };
  }

  arrivalDateValidator(departureDateControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const formGroup = control.parent;
      if (!formGroup) {
        return null;
      }
      const departureDate = formGroup.get(departureDateControlName)?.value;
      const arrivalDate = control.value;
      if (arrivalDate && departureDate && new Date(departureDate) <= new Date(arrivalDate)) {
        return {'departureDateInvalid': true};
      }
      return null;
    };
  }

  isRequiredField(field: string) {
    const form_field = this.form.get(field);
    if (!form_field.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }
}
