<div [formGroup]="form" class="space-y-2 w-full">
  <div class="grid grid-cols-3 gap-3">
    <div class="pl-2 pr-2">
      <div><label for="">Imbarcazione <span *ngIf="isRequiredField('boat')">*</span></label></div>
      <ng-select (focus)="boat$.next('')"
                 [items]="boatList"
                 [typeahead]="boat$"
                 bindLabel="name"
                 formControlName="boat">
      </ng-select>


    </div>
    <div>
      <div><label for="">Posto barca <span *ngIf="isRequiredField('berth')">*</span></label></div>
      <ng-select (focus)="berths$.next('')"
                 [items]="berthList"
                 [typeahead]="berths$"
                 bindLabel="code"
                 formControlName="berth">
      </ng-select>
    </div>
    <div>
      <div><label for="">Date prenotazione <span *ngIf="isRequiredField('dates')">*</span></label></div>

      <app-ngb-datepicker-range formControlName="dates"></app-ngb-datepicker-range>
    </div>
    <div class="pl-2 pr-2">
      <div><label for="">Prezzo <span *ngIf="isRequiredField('price')">*</span></label></div>
      <div class="relative">
        <input class="pl-4 py-1 text-xs w-full placeholder:text-gray-500 form-control border rounded-lg "
               style="padding-right: 2.8rem"
               formControlName="price" type="number" min="0.00" step="1"/>
        <div class="absolute top-1 right-3 pl-2 pr-3 border rounded-lg bg-gray-100" [matMenuTriggerFor]="priceMenu">
          <span class="font-bold text-sm">{{ getCurrency(form.getRawValue().currencyIso3) }}</span>
        </div>
        <mat-menu #priceMenu="matMenu">
          <button class="flex space-x-2" mat-menu-item [matTooltip]="cur.name" matTooltipShowDelay="1000"
                  matTooltipPosition="left" (click)="form.get('currencyIso3').setValue(cur.symbol)"
                  *ngFor="let cur of currency">
            <span class="text-sm">{{ cur.symbol }}</span>
          </button>

        </mat-menu>
      </div>
    </div>
  </div>
</div>
