import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BoatService} from "../../service/boat.service";
import {BoatEngineService} from "../../service/boat-engine.service";
import {CountryService} from "../../service/country.service";
import {NgEventBus} from "ng-event-bus";
import {ShipOwnerService} from "../../service/ship-owner.service";
import {SkipperService} from "../../service/skipper.service";
import {ShipYardService} from "../../service/ship-yard.service";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {InsuranceDataService} from "../../service/insurance-data.service";
import {AttachmentService} from "../../service/attachment.service";
import {ToastrService} from "ngx-toastr";
import {BoatTypeService} from "../../service/boat-type.service";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  iif,
  map,
  mergeMap,
  Observable,
  of,
  ReplaySubject,
  switchMap,
  tap
} from "rxjs";
import {catchError} from "rxjs/operators";
import {ExpressionBuilder} from "../../service/ExpressionBuilder";
import {faCalendarAlt, faCogs, faFileContract, faPlus, faSave, faShip} from "@fortawesome/free-solid-svg-icons";

import {
  AttachmentType,
  BoatDTO,
  BoatEngineDTO,
  BoatEngineDTO_PropulsionType,
  BoatTypeDTO,
  CountryDTO,
  InsuranceDataDTO,
  ShipOwnerDTO,
  ShipYardDataDTO,
  SkipperDTO
} from "../../service/dto";
import {indicate} from "../../utils/utils";
import {
  BoatInsuranceDataModalComponent
} from "../../boats/boat-detail/boat-insurance-data-modal/boat-insurance-data-modal.component";
import moment from "moment";

export type OpType = 'SAVED' | 'UPDATED'

@Component({
  selector: 'app-boat-form',
  templateUrl: './boat-form.component.html',
  styleUrl: './boat-form.component.scss'
})
export class BoatFormComponent implements OnInit {

  private _boatId: number
  @Output()
  onBoatLoaded: EventEmitter<BoatDTO> = new EventEmitter();
  @Output()
  onBoatSaved: EventEmitter<{ boat: BoatDTO, op: OpType }> = new EventEmitter();
  loading$ = new ReplaySubject<boolean>();
  data = new ReplaySubject();
  data$: Observable<BoatDTO>;
  boat!: BoatDTO
  form: FormGroup;
  ownerForm: FormGroup;
  skipperForm: FormGroup;
  boatTypes$ = new ReplaySubject<string>()
  boatTypesList: BoatTypeDTO[] = []
  countries$ = new ReplaySubject<string>()
  countryList: CountryDTO[] = []
  shipOwner$ = new ReplaySubject<string>()
  shipOwnerList: ShipOwnerDTO[] = []
  skipper$ = new ReplaySubject<string>()
  skipperList: SkipperDTO[] = []
  insuranceData$ = new ReplaySubject<string>()
  insuranceData: Observable<InsuranceDataDTO[]>
  cantiereForm: FormGroup;
  propulsioneForm: FormGroup;
  shipOwnershipSelected: ShipOwnerDTO;
  skipperSelected: SkipperDTO;
  now = new Date()
  protected readonly faSave = faSave;
  protected readonly faFileContract = faFileContract;
  protected readonly faShip = faShip;
  protected readonly faCogs = faCogs;

  constructor(private formBuilder: FormBuilder,
              private boatService: BoatService,
              private engineService: BoatEngineService,
              private countryService: CountryService,
              private eventBus: NgEventBus,
              private shipOwnerService: ShipOwnerService,
              private skipperService: SkipperService,
              private shipYardService: ShipYardService,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private insuranceDataService: InsuranceDataService,
              private attachmentService: AttachmentService,
              private router: Router,
              private toastService: ToastrService,
              private boatTypeService: BoatTypeService) {
    this.form = this.formBuilder.group({
      boat: [undefined, Validators.required],
    });

    this.ownerForm = this.formBuilder.group({
      owner: undefined
    });

    this.skipperForm = this.formBuilder.group({
      skipper: undefined

    });

    this.cantiereForm = this.formBuilder.group({
      cantiereName: undefined,
      cantiereModel: undefined,
      cantiereDesigner: undefined,
      cantiereBuildYear: undefined,
      cantiereRegistrationDate: undefined
    });

    this.propulsioneForm = this.formBuilder.group({
      modelloMotore: undefined,
      marcaMotore: undefined,
      matricolaMotore: undefined,
      potenzaMaxCV: undefined,
      potenzaMaxKW: undefined,
    });

    this.data.pipe(
      switchMap(x => this.boatService.getById(this._boatId, {images: true, engine: true, skipper: true}).pipe(
        catchError(e => {
          this.toastService.error("Errore durante il recupero dei dati della barca")
          throw e
        }),
        tap(boat => {
          this.boat = boat
          this.onBoatLoaded.emit(this.boat)
          this.updateForm(boat)
          // this.updateEngineForm(boat);
          // if (boat.skipper) {
          //   this.updateSkipper(boat.skipper);
          // }
        })
      ))
    ).subscribe()

    this.boatTypes$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(x => {
        const exp = ExpressionBuilder.getBuilder().root();
        if (!x)
          return exp
        exp.ilike('name', '%' + x + '%')
        return exp
      }),
      switchMap(x => this.boatTypeService.getAll(0, 1000, x).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),
          map(x => x.data),
          tap(x => this.boatTypesList = x)
        )
      )
    ).subscribe();

    this.countries$.pipe(
      map(x => {
        const exp = ExpressionBuilder.getBuilder().root();
        if (!x)
          return exp
        exp.ilike('name', '%' + x + '%')
        return exp
      }),
      switchMap(x => this.countryService.getAll(0, 1000, x).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),
          map(x => x.data),
          tap(x => this.countryList = x)
        )
      )
    ).subscribe()


    this.shipOwner$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(x => {
        let exp = ExpressionBuilder.getBuilder().or();
        if (!x)
          return exp

        exp.ilike('firstname', '%' + x + '%').ilike('lastname', '%' + x + '%')
        return exp
      }),
      switchMap(x => this.shipOwnerService.getAll(0, 100, x).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),
          map(x => x.data.map(y => {
            return {
              ...y,
              fullName: y.firstname + ' ' + (y.lastname??'')
            }
          })),
          tap(x => this.shipOwnerList = x)
        )
      )
    ).subscribe()

    this.skipper$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(x => {
        let exp = ExpressionBuilder.getBuilder().or();
        if (!x)
          return exp

        exp.ilike('firstname', '%' + x + '%').ilike('lastname', '%' + x + '%')
        return exp
      }),
      switchMap(x => this.skipperService.getAll(0, 100, x).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),
          map(x => x.data.map(y => {
            return {
              ...y,
              fullName: y.firstname + ' ' + (y.lastname??'')
            }
          })),
          tap(x => this.skipperList = x)
        )
      )
    ).subscribe()

    this.insuranceData = this.insuranceData$.pipe(
      map(x => {
        const exp = ExpressionBuilder.getBuilder().root().eq('boatId', this._boatId)
        return exp
      }),
      switchMap(x => this.insuranceDataService.getAll(0, 50, x).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il salvataggio dei dati")
            throw e
          }),
          map(x => x.data)
        )
      )
    )
  }


  ngOnInit(): void {
    this.boatTypes$.next('')
    this.countries$.next('')
    if (!this.isNew()) {
      this.refreshData()
    } else {
      for (let control in this.cantiereForm.controls) {
        this.cantiereForm.controls[control].disable({emitEvent: false});
      }
      for (let control in this.propulsioneForm.controls) {
        this.propulsioneForm.controls[control].disable({emitEvent: false});
      }
    }
    this.form.get('boat').valueChanges.subscribe(value => {
      if (this.isMotorBoat()) {
        this.enableMotorFields();
      } else {
        this.propulsioneForm.reset();
        this.disableMotorFields();
      }
    });
  }

  isMotorBoat(): boolean {
    return this.form.get('boat')?.getRawValue()?.boatType?.name === 'Barca a motore';
  }

  enableMotorFields() {
    this.propulsioneForm.get('modelloMotore').enable();
    this.propulsioneForm.get('marcaMotore').enable();
    this.propulsioneForm.get('matricolaMotore').enable();
    this.propulsioneForm.get('potenzaMaxCV').enable();
    this.propulsioneForm.get('potenzaMaxKW').enable();
  }

  disableMotorFields() {
    this.propulsioneForm.get('modelloMotore').disable();
    this.propulsioneForm.get('marcaMotore').disable();
    this.propulsioneForm.get('matricolaMotore').disable();
    this.propulsioneForm.get('potenzaMaxCV').disable();
    this.propulsioneForm.get('potenzaMaxKW').disable();
  }

  save() {

    const engine = {
      model: this.propulsioneForm.getRawValue().modelloMotore,
      brand: this.propulsioneForm.getRawValue().marcaMotore,
      kw: this.propulsioneForm.getRawValue().potenzaMaxKW,
      cv: this.propulsioneForm.getRawValue().potenzaMaxCV,
      serialNumber: this.propulsioneForm.getRawValue().matricolaMotore,
      boatId: this._boatId,
    } as BoatEngineDTO;

    const shipOwnerObj = {
      firstname: this.ownerForm.getRawValue().owner?.firstname,
      lastname: this.ownerForm.getRawValue().owner?.lastname,
      vatCode: this.ownerForm.getRawValue().owner?.vatCode,
      taxCode: this.ownerForm.getRawValue().owner?.taxCode,
      mobilePhone: this.ownerForm.getRawValue().owner?.mobilePhone,
      phone: this.ownerForm.getRawValue().owner?.phone,
      email: this.ownerForm.getRawValue().owner?.email,
      pec: this.ownerForm.getRawValue().owner?.pec,
      type: this.ownerForm.getRawValue().owner?.type,
    } as ShipOwnerDTO;

    const skipperObj = {
      id: this.skipperSelected?.id,
      firstname: this.skipperForm.getRawValue().skipper?.firstname,
      lastname: this.skipperForm.getRawValue().skipper?.lastname,
      mobilePhone: this.skipperForm.getRawValue().skipper?.mobilePhone,
      email: this.skipperForm.getRawValue().skipper?.email,
    } as SkipperDTO

    const obj = {
      beam: this.form.get('boat').getRawValue().beam,
      description: this.form.get('boat').getRawValue().description,
      draft: this.form.get('boat').getRawValue().draft,
      length: this.form.get('boat').getRawValue().length,
      boatModel: this.form.get('boat').getRawValue().boatModel,
      plateNumber: this.form.get('boat').getRawValue().plateNumber,
      flagId: this.form.get('boat').getRawValue().flag?.id,
      name: this.form.get('boat').getRawValue().name,
      typeId: this.form.get('boat').getRawValue().boatType?.id,
      maxPassengers: this.form.get('boat').getRawValue().maxPassengers,
      registryNumber: this.form.get('boat').getRawValue().registryNumber,
      shipOwnerId: this.shipOwnershipSelected?.id,
      engine: !this.isMotorBoat() ? null : engine,
      skipperId: this.skipperSelected?.id,
      skipper: this.skipperForm.valid && !this.skipperSelected ? skipperObj : null
    } as BoatDTO;

    const addShipOwner = (shipOwnerObj: ShipOwnerDTO) => this.shipOwnerService.add(shipOwnerObj)
    const addSkipper = (skipperObj: SkipperDTO) => this.skipperService.add(skipperObj)

    if (this.isNew()) {

      of(obj).pipe(
        indicate(this.loading$),
        mergeMap(owner => iif(() => !obj?.shipOwnerId, addShipOwner(shipOwnerObj).pipe(tap(x => obj.shipOwnerId = x.id)), of(obj))),
        mergeMap(owner => iif(() => !obj?.skipperId && this.skipperForm.valid, addSkipper(skipperObj).pipe(tap(x => obj.skipperId = x.id)), of(obj))))
        .pipe(
          catchError(e => {
            this.toastService.error("Errore durante il salvataggio dei dati");
            throw e;
          }),
          switchMap(() => this.boatService.add(obj)
          ),
        ).subscribe(x => {
        this.onBoatSaved.emit({boat: x, op: 'SAVED'})

      });
    } else {
      this.boatService.update(this._boatId, obj, {skipper: true}).pipe(
        indicate(this.loading$),
        catchError(e => {
          this.toastService.error("Errore durante l'aggiornamento dei dati della barca");
          throw e;
        })
      ).subscribe(x => {
        this.updateSkipperForm(x.skipper)
        if (this.skipperForm.valid)
          this.updateSkipper(x.skipper)
        this.onBoatSaved.emit({boat: x, op: 'UPDATED'})
      });
    }
  }

  saveShipYard() {
    const shipYardObj = {
      name: this.cantiereForm.getRawValue().cantiereName,
      model: this.cantiereForm.getRawValue().cantiereModel,
      designer: this.cantiereForm.getRawValue().cantiereDesigner,
      buildYear: this.formatDate(this.cantiereForm.getRawValue().cantiereBuildYear),
      registrationDate: this.formatDate(this.cantiereForm.getRawValue().cantiereRegistrationDate),
      boatId: this._boatId
    } as ShipYardDataDTO;

    this.shipYardService.getByBoatId(this._boatId).pipe(
      catchError(e => {
        console.error("Errore durante il salvataggio dei dati del cantiere", e);
        this.toastService.error("Errore durante il salvataggio dei dati del cantiere");
        throw e;
      }),
      tap(existingShipYard => {
        console.log("Existing ShipYard:", existingShipYard);
      }),
      mergeMap(existingShipYard =>
        iif(() => !!existingShipYard?.id,
          this.shipYardService.update(existingShipYard.id, shipYardObj),
          this.shipYardService.add(shipYardObj)
        )
      )
    ).subscribe(
      () => {
        this.toastService.success("Dati del cantiere salvati con successo");
        this.refreshData();
      }
    );
  }


  savePropulsion() {
    if (!this.isMotorBoat()) {
      console.log("La barca non è a motore, quindi non è possibile salvare i dati del motore.");
      return;
    }

    const engineObj = {
      model: this.propulsioneForm.getRawValue().modelloMotore,
      brand: this.propulsioneForm.getRawValue().marcaMotore,
      kw: this.propulsioneForm.getRawValue().potenzaMaxKW,
      cv: this.propulsioneForm.getRawValue().potenzaMaxCV,
      serialNumber: this.propulsioneForm.getRawValue().matricolaMotore,
      propulsionType: BoatEngineDTO_PropulsionType.ENGINE,
      boatId: this._boatId
    } as BoatEngineDTO;

    if (!this.boat.engine) {
      this.engineService.addEngine(engineObj).pipe(
        catchError(e => {
          this.toastService.error("Errore durante il salvataggio dei dati del motore della barca");
          throw e
        })
      ).subscribe(
        (response) => {
          console.log("Response after adding engine:", response);
          this.toastService.success("Salvataggio dei dati del motore effettuato");
          this.boat.engine = response;
        }
      );
    } else {
      this.engineService.updateEngine(this.boat.engine.id, engineObj).pipe(
        catchError(e => {
          this.toastService.error("Errore durante l'aggiornamento dei dati del motore della barca");
          throw e;
        }),
      ).subscribe(
        (response) => {
          this.toastService.success("Aggiornamento dei dati del motore effettuato");
          this.boat.engine = response;
        }
      );
    }


  }

  private formatDate(date: string): string | null {
    if (!date) {
      return null;
    }
    const d = new Date(date);
    return d.toISOString();
  }


  isNew() {
    return this._boatId < 0;
  }

  private refreshData() {
    if (!this.isNew())
      this.data.next(null);


    this.shipYardService.getByBoatId(this._boatId).pipe(
      catchError(e => {
        this.toastService.error("Errore durante il recupero dei dati del cantiere");
        throw e;
      }),
    ).subscribe(shipYard => {
      this.updateCantiereForm(shipYard);
    });

    this.insuranceData$.next(null)
  }

  private updateCantiereForm(shipYard: ShipYardDataDTO) {
    if (!shipYard) return;
    //console.log('Updating cantiere formData with:', shipYard);

    this.cantiereForm.patchValue({
      cantiereName: shipYard.name,
      cantiereModel: shipYard.model,
      cantiereDesigner: shipYard.designer,
      cantiereBuildYear: this.toDateString(shipYard.buildYear),
      cantiereRegistrationDate: this.toDateString(shipYard.registrationDate)
    });
  }

  private toDateString(date: string): string | null {
    if (!date) {
      return null;
    }
    return moment(date).format('YYYY-MM-DD')
  }

  private updateEngineForm(boat: BoatDTO) {
    if (!boat.engine) {
      this.propulsioneForm.reset();
    } else {
      const engine = boat.engine;
      this.propulsioneForm.patchValue({
        modelloMotore: engine.model,
        marcaMotore: engine.brand,
        potenzaMaxKW: engine.kw,
        potenzaMaxCV: engine.cv,
        matricolaMotore: engine.serialNumber
      });
    }
  }


  private updateForm(boat: BoatDTO) {
    if (!boat)
      return;
    this.form.patchValue({
      boat: boat
    })
    // this.form.patchValue({
    //   name: boat.name,
    //   beam: boat.beam,
    //   description: boat.description,
    //   draft: boat.draft,
    //   length: boat.length,
    //   boatModel: boat.boatModel,
    //   plateNumber: boat.plateNumber,
    //   flag: boat.flag,
    //   boatType: boat.type,
    //   maxPassengers: boat.maxPassengers,
    //   registryNumber: boat.registryNumber,
    // });
    this.shipOwnershipSelected = boat.shipOwner;
    this.updateOwnerForm(boat.shipOwner)

    if (boat.shipOwner) {
      for (let control in this.ownerForm.controls) {
        this.ownerForm.controls[control].disable({emitEvent: false});
      }
    }

  }

  updateOwner(event: ShipOwnerDTO) {
    this.shipOwnershipSelected = event;
    this.updateOwnerForm(event);
    this.disableFields(this.ownerForm);
  }

  private updateOwnerForm(shipOwner: ShipOwnerDTO) {
    if (!shipOwner) {
      return
    }
    this.ownerForm.patchValue({
      owner: shipOwner
    });

  }

  updateSkipper(event: SkipperDTO) {
    this.skipperSelected = event;
    this.updateSkipperForm(event);
    this.disableFields(this.skipperForm);
  }

  private updateSkipperForm(skipper: SkipperDTO) {
    if (!skipper) {
      return
    }
    this.skipperForm.patchValue({
      skipper: skipper

    });

  }


  openInsuranceModal(insurance?: InsuranceDataDTO) {
    const ref = this.dialog.open(BoatInsuranceDataModalComponent, {
      width: '40%',
      height: '30%',
      panelClass: 'customModal',
      data: {insurance: insurance, boatId: this._boatId},
    });

    ref.componentInstance.save$.pipe(
      filter(x => !!x),
      switchMap(x => this.insuranceDataService.add(x).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),
          tap(x => this.toastService.info("Dati aggiornati")
          ),
          tap(x => this.refreshData()),
          tap(x => ref.close())
        )
      )
    ).subscribe()
  }

  protected readonly faPlus = faPlus;
  protected readonly moment = moment;

  disableFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((controlName) => {
      const control: AbstractControl = formGroup.get(controlName);
      control.disable();
    });
  }

  enableFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((controlName) => {
      const control: AbstractControl = formGroup.get(controlName);
      control.enable();
    });
  }

  onClearOwner() {
    if (this.isNew()) {
      this.enableFields(this.ownerForm);
      this.ownerForm.patchValue({
        firstname: undefined,
        lastname: undefined,
        vatCode: undefined,
        taxCode: undefined,
        mobilePhone: undefined,
        phone: undefined,
        email: undefined,
        pec: undefined,
        type: undefined
      })
    }
  }

  onClearSkipper() {
    if (this.isNew()) {
      this.enableFields(this.skipperForm);
      this.skipperForm.patchValue({
        firstname: undefined,
        lastname: undefined,
        mobilePhone: undefined,
        email: undefined,
      })
    }
  }

  onFileUpdated(file: File) {
    if (!file)
      return
    this.attachmentService.uploadFile(file, AttachmentType.BOAT_IMAGE, null, this._boatId).pipe(
      catchError(e => {
        this.toastService.error("Errore durante l'upload")
        throw e
      }),
    ).subscribe(x => {
      this.toastService.success("Immagine caricata")
      this.refreshData()
    })
  }

  onBoatFormUpdated(boat: BoatDTO) {
    this.shipOwnershipSelected = boat.shipOwner;
    this.updateOwnerForm(boat.shipOwner)

    if (boat.shipOwner) {
      for (let control in this.ownerForm.controls) {
        this.ownerForm.controls[control].disable({emitEvent: false});
      }
    }
    this.updateEngineForm(boat);
    if (boat.skipper) {
      this.updateSkipper(boat.skipper);
    }
  }


  get boatId(): number {
    return this._boatId;
  }

  @Input()
  set boatId(value: number) {
    this._boatId = value;
    this.refreshData()
  }

  isRequiredField(field: string) {
    const form_field = this.form.get(field);
    if (!form_field.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }

  protected readonly faCalendarAlt = faCalendarAlt;
}
