import {Injectable, OnDestroy} from '@angular/core';
import {combineLatest, filter, map, Observable, Subject, takeUntil} from "rxjs";

import {Apollo, gql, MutationResult} from "apollo-angular";
import {BoolOperation, ExpressionBuilder} from "./ExpressionBuilder";
import {AttachmentDirectoryDTO} from "./dto";
import {HttpClient} from "@angular/common/http";
import {ATTACHMENT_FIELD} from "./attachment.service";

export const ATTACHMENT_DIRECTORY_FIELD = gql`
  fragment ATTACHMENT_DIRECTORY_FIELD on AttachmentDirectoryDTO {
    id
    name
    parentId
    createdAt
    updatedAt
    boatId
    shipOwnerId
  }
`;


const add = gql`
  ${ATTACHMENT_DIRECTORY_FIELD}

  mutation AddDirectory($dto: AttachmentDirectoryInput!) {
    attachmentDirectory_add_one(body: $dto) {
      ...ATTACHMENT_DIRECTORY_FIELD
    }
  }`
const update = gql`
  ${ATTACHMENT_DIRECTORY_FIELD}

  mutation UpdateDirectory($id: Int!, $dto: AttachmentDirectoryInput!) {
    attachmentDirectory_update_one(pk_columns: {id: $id}, body: $dto){
      ...ATTACHMENT_DIRECTORY_FIELD

    }

  }`


const getAll = gql`
  ${ATTACHMENT_DIRECTORY_FIELD}
  ${ATTACHMENT_FIELD}

  query GetAllDirectory($pageNumber: Int, $pageDim: Int, $where:String, $includeParent: Boolean = false, $includeFiles: Boolean = false ) {
    attachmentDirectory(pageNum: $pageNumber, pageDim: $pageDim, where: $where, sort: "id,desc") {
      ...ATTACHMENT_DIRECTORY_FIELD
      parent @include(if: $includeParent) {
        ...ATTACHMENT_DIRECTORY_FIELD
      }
      files @include(if: $includeFiles) {
        ...ATTACHMENT_FIELD
      }
    }
  }
`;

const getById = gql`
  ${ATTACHMENT_DIRECTORY_FIELD}
  ${ATTACHMENT_FIELD}

  query GetDirectoryById($id: ID!, $includeParent: Boolean = false, $includeFiles: Boolean = false ) {
    attachmentDirectory_by_pk(id: $id) {
      ...ATTACHMENT_DIRECTORY_FIELD
      parent @include(if: $includeParent) {
        ...ATTACHMENT_DIRECTORY_FIELD
      }
      files @include(if: $includeFiles) {
        ...ATTACHMENT_FIELD
      }
    }
  }`;


const count = gql`query CountDirectory( $where: String ) {
  attachmentDirectory_aggregate(aggregation: {count: {field: "id", distinct: false}}, where: $where) {
    count
  }
}`;

const del = gql`
  ${ATTACHMENT_DIRECTORY_FIELD}

  mutation deleteDirectory($id: ID!) {
    attachmentDirectory_delete_one(id:  $id){
      ...ATTACHMENT_DIRECTORY_FIELD
    }

  }`
@Injectable({
  providedIn: 'root'
})
export class AttachmentDirectoryService implements OnDestroy {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apollo: Apollo, private http: HttpClient) {

  }

  getAll(page: number = 0, pageSize: number = 10, filters?: BoolOperation, sort?: string, includes?:{parent?: boolean, files?: boolean} ): Observable<{
    data: AttachmentDirectoryDTO[],
    totalRows: number
  }> {
    const inc = {...{parent: false, files: false}, ...includes}
    const fetchData = this.apollo
      .watchQuery({
        query: getAll,
        variables: {
          pageNumber: page,
          pageDim: pageSize,
          where: ExpressionBuilder.toGql(filters),
          includeParent: inc.parent,
          includeFiles: inc.files
        },
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    const fetchCount = this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    return combineLatest([fetchData, fetchCount]).pipe(
      takeUntil(this.destroy$),
      filter(x => !!x[0].data),
      map(x => {
        // @ts-ignore
        const aggRes = x[1].data['attachmentDirectory_aggregate']
        // @ts-ignore
        const data = x[0].data?.['attachmentDirectory']
        const d = data.map((y: any) => (({__typename, ...o}) => o)(y));
        return {
          data: d,
          totalRows: aggRes.count
        }
      })
    )
  }

  getById(id: number, includes?:{parent: boolean, files: boolean}): Observable<AttachmentDirectoryDTO> {

    const inc = {...{parent: false, files: false}, ...includes}
    return this.apollo.watchQuery({
      query: getById,
      variables: {
        id: id,
        includeParent: inc.parent,
        includeFiles: inc.files
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      filter(x => !x.loading),
      takeUntil(this.destroy$),
      filter(x => !x.loading),
      map(x => {
          // @ts-ignore
          const data = x.data?.['attachmentDirectory_by_pk'];
          const d = (({__typename, ...o}) => o)(data)
          return d
        }
      )
    );
  }

  //

  add(dto: AttachmentDirectoryDTO): Observable<AttachmentDirectoryDTO> {
    return this.apollo.mutate({
      mutation: add,
      variables: {dto: dto},
      fetchPolicy: 'no-cache',
    }).pipe(map((x: MutationResult) => x.data.insert_attachmentDirectory_one))
  }

  update(id: number, dto: AttachmentDirectoryDTO): Observable<AttachmentDirectoryDTO> {
    return this.apollo.mutate({
      mutation: update,
      variables: {id: id, dto: dto},
      fetchPolicy: 'no-cache',

    }).pipe(map((x: MutationResult) => x.data.attachmentDirectory_by_pk))
  }

  delete(id: number): Observable<AttachmentDirectoryDTO> {
    return this.apollo.mutate({
      mutation: del,
      variables: {id: id, },
      fetchPolicy: 'no-cache',

    }).pipe(map((x: MutationResult) => x.data.attachmentDirectory_delete_one))
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
