<mat-sidenav-container class="drop-container" [hasBackdrop]="false">
  <mat-sidenav mode="over" position="end" fixedInViewport [opened]="!!sideNavMode" fixedTopGap="0">
    <ng-container *ngTemplateOutlet="sideNav"></ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </mat-sidenav-content>
</mat-sidenav-container>
<ng-template #content>
  <div class="flex justify-between items-center mb-3 min-w-lg mr-1">
    <div class="flex space-x-4 items-center" [formGroup]="filterForm">
      <!--      <app-search-bar class="w-full min-w-[20rem]" (onSearch)="search($event)" (onClear)="clear($event)"-->
      <!--                      placeholder="Ricerca per nome file">-->
      <!--      </app-search-bar>-->
      <!--      <mat-formData-field class="fuse-mat-rounded fuse-mat-dense xs w-full text-sm min-w-[14rem]">-->
      <!--        <mat-label>Data creazione:</mat-label>-->
      <!--        <mat-date-range-input [rangePicker]="picker">-->
      <!--          <input matStartDate formControlName="dateFrom" placeholder="Start date">-->
      <!--          <input matEndDate formControlName="dateTo" placeholder="End date">-->
      <!--        </mat-date-range-input>-->
      <!--        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
      <!--        <mat-date-range-picker #picker></mat-date-range-picker>-->
      <!--        <mat-icon matPrefix class="cursor-pointer" svgIcon="clear" (click)="clearDates()">-->
      <!--        </mat-icon>-->
      <!--      </mat-formData-field>-->
    </div>

    <button (click)="addNew()"
            *ngIf="!readonly"
            class="inline-block  px-8 py-2 m-0 text-xs font-bold text-center text-white uppercase align-middle transition-all border-0 rounded-lg cursor-pointer ease-in-out tracking-tight bg-gradient-to-tl from-blue-500  to-cyan-500 shadow-soft-md bg-150 bg-x-25 hover:scale-[102%] active:opacity-85">
      <fa-icon [icon]="faPlus"></fa-icon>
      Nuovo allegato
    </button>
  </div>
  <ng-container *ngIf="mode === 'DIRECTORY'">
    <div class="flex flex-row space-x-6 mb-6 items-center justify-between">
      <div class="cursor-pointer">
        <button mat-icon-button matTooltip="Cartella superiore" (click)="goBack()"
                [disabled]="rootDir == this.getCurrentDir()">
          <mat-icon svgIcon="feather:corner-left-up"></mat-icon>
        </button>
      </div>
      <div class="text-center relative overflow-x-auto">
        <b>Cartella corrente:</b><span class="truncate-left">
        {{ (this.getHistoryPath() || 'Root') }}</span>
      </div>
      <div>
        <button mat-icon-button matTooltip="Torna alla root" (click)="goHome()"
                [disabled]="rootDir == this.getCurrentDir()">
          <mat-icon svgIcon="feather:home"></mat-icon>
        </button>
        <button mat-icon-button class="hover:text-red-900" matTooltip="Elimina cartella" *ngIf="!readonly "
                [disabled]="rootDir == this.getCurrentDir()"
                (click)="deleteDirectory(this.getCurrentDir())">
          <mat-icon svgIcon="feather:trash-2"></mat-icon>
        </button>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="directoryBox;context:{parentDir:getCurrentDir()}"></ng-container>

    <hr>
  </ng-container>

  <div class=" mt-6 mb6"><b>Files</b></div>
  <div appDropArea class="w-full min-h-36 rounded">
    <!--         (onFileOver)="onFileOver($event)"-->
    <!--         (fileDropped)="onFileDropped($event)">-->
    <div class="flex flex-wrap flex-row grow " *ngIf="attachments$|async as files; else waiting">
      <!--            <input type="file" #fileDropRef id="fileDropRef" style="display: none"-->
      <!--                   (change)="fileBrowseHandler($event.target.files)"/>-->
      @if (files.length == 0) {
        <div class="mt-4"><span>Nessun file presente.</span></div>
      } @else {
        <a class="flex flex-col w-40 h-40 m-2 p-4 shadow rounded-2xl cursor-pointer bg-card over-menu-container"
           [class.expiring]="isExpiring(file)" [class.expired]="isExpired(file)" *ngFor="let file of files">
          <div class="over-menu">
            <div class="space-x-3">
              <button mat-icon-button (click)="selectFile(file)" class="menu-button">
                <mat-icon svgIcon="feather:info" matTooltip="Apri dettagli"></mat-icon>
              </button>

            </div>
          </div>
          <div class="aspect-[9/6]">
            <div class="flex items-center justify-center h-full">
              <div class="relative text-slate-700">
                <mat-icon style="width: 75px; height: 75px">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor" fit="" height="100%" width="100%"
                       preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z">
                    </path>
                  </svg>
                </mat-icon>
                <div class="tag px-1.5 rounded text-sm font-semibold leading-5 text-white"
                     [style.background-color]="getExtensionColor(file?.name.split('.').pop())">
                  {{ file?.name.split('.').pop()| uppercase }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col flex-auto justify-center text-center text-sm font-medium">
            <div class="mat-mdc-tooltip-trigger truncate">
              {{ file?.name.split('.').slice(0, -1).join('.')|titlecase }}
            </div>
          </div>
        </a>
      }
    </div>
  </div>
</ng-template>


<ng-template #sideNav>
  <div class="p-4 min-w-96">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl  font-bold tracking-tight leading-7 truncate" *ngIf="!currentFile">Nuovo allegato</h2>
      <h2 class="text-xl  font-bold tracking-tight leading-7 truncate" *ngIf="currentFile">Dettagli allegato </h2>
      <button mat-icon-button (click)="cancel()" class="scale-75">
        <mat-icon svgIcon="mat_outline:close"></mat-icon>
      </button>
    </div>
    <ng-container *ngIf="sideNavMode == 'SHOW_DETAIL'">
      <div class="flex flex-col flex-auto p-4 relative">
        <div class="absolute top-[26px] right-[26px]">

          <button mat-icon-button (click)="delete(currentFile)" *ngIf="!readonly">
            <mat-icon svgIcon="feather:trash-2"
                      class="mat-icon notranslate icon-size-5 mr-2 hover:text-red-900"></mat-icon>
          </button>
        </div>
        <div class="aspect-[9/6]">
          <div class="flex items-center justify-center h-full border rounded-lg bg-gray-50 dark:bg-card">
            <div class="relative">
              <mat-icon style="width: 75px; height: 75px" id="2322">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" fit="" height="100%" width="100%"
                     preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z">
                  </path>
                </svg>
              </mat-icon>
              <div class="tag px-1.5 rounded text-sm font-semibold leading-5 text-white"
                   [style.background-color]="getExtensionColor(this.currentFile?.name.split('.').pop())">
                {{ currentFile?.name.split('.').pop()| uppercase }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center mt-2 space-x-8">
          <div class="flex flex-row space-x-4 items-center justify-between w-full">
            <!--            <ng-container *ngIf="!filenameEditable || readonly; else editFilenameInput">-->
            <div class="text-xl font-medium truncate max-w-60" [matTooltip]="currentFile?.name">
              {{ currentFile?.name| titlecase }}
            </div>
            <div class="self-end">
              <div>
                <button mat-icon-button matTooltip="Rinomina file" (click)="editFileName()" *ngIf="!readonly">
                  <mat-icon svgIcon="feather:edit"
                            class="mat-icon notranslate icon-size-4 mr-2 mat-icon-no-color"></mat-icon>
                </button>
              </div>
              <!--            </ng-container>-->
              <!--            <ng-template #editFilenameInput>-->
              <!--              <div>-->
              <!--                <input matInput>-->

              <!--              </div>-->
              <!--              <div>-->
              <!--                <button mat-icon-button (click)="updateFileName()">-->
              <!--                  <mat-icon svgIcon="feather:save"-->
              <!--                            class="mat-icon notranslate icon-size-4 mr-2 mat-icon-no-color"></mat-icon>-->
              <!--                </button>-->
              <!--              </div>-->
              <!--            </ng-template>-->
            </div>
          </div>
          <div>
            <a download [href]="currentFile.url" class="hidden" #downloadLink></a>
            <button mat-icon-button matTooltip="Scarica file" (click)="downloadLink.click()">
              <mat-icon svgIcon="mat_outline:download"></mat-icon>

            </button>
          </div>
        </div>
        <div class="text-lg font-medium mt-4">Informazioni</div>
        <div class="flex flex-col mt-4 border-t border-b divide-y font-medium">


          <ng-container *ngIf="docExpiring">
            <div class="flex items-center justify-between py-3">
              <div class="text-secondary">Scadenza</div>
              <div>{{ currentFile?.expiration | localizeDate }}</div>
            </div>
          </ng-container>
          <div class="flex items-center justify-between py-3">
            <div class="text-secondary">Caricato il</div>
            <div class="text-sm">{{ currentFile?.createdAt|date: 'yyyy-MM-dd H:mm':'offset' }}</div>
          </div>

          <div class="flex items-center justify-between py-3">
            <div class="text-secondary">Dimensione</div>
            <div class="text-sm">{{ getUnitMeasure(currentFile?.contentLength) }}</div>
          </div>

        </div>
        <div class="flex items-center justify-between mt-4">
          <div class="text-lg font-medium">Descrizione</div>
        </div>
        <div class="mt-2">
             <textarea [(ngModel)]="currentFile.description" [disabled]="readonly"
                       class="text-sm  w-full f placeholder:text-gray-500 form-control"
                       placeholder="Inserisci una descrizione" rows="6">

             </textarea>
        </div>
        <div class="grid grid-cols-2 gap-4 w-full mt-4">
          <button mat-button class="mat-focus-indicator ml-3 mat-button mat-button-base mat-primary"
                  (click)="cancel()">
                        <span class="mat-button-wrapper">
                            Indietro
                        </span>
          </button>

          <button mat-flat-button class="btn btn-primary flex cursor-pointer"
                  [disabled]="readonly" (click)="update()">
            <fa-icon [icon]="faSave" class="mr-2  "></fa-icon>
            Salva
          </button>
        </div>
      </div>

    </ng-container>
    <ng-container *ngIf="sideNavMode == 'NEW_FILE'">
      <ng-container *ngTemplateOutlet="newFile"></ng-container>
    </ng-container>
  </div>

</ng-template>


<ng-template #newFile>

  <div class="flex flex-col flex-auto " [formGroup]="form">
    <div class="">
      <div class="p-3 flex flex-col border rounded-lg bg-gray-50 dark:bg-card dropArea h-48 "
           #dragContainer
           (dragover)="applyCss(dragContainer, $event)"
           (dragleave)="removeCssClass(dragContainer)"
           (drop)="removeCssClass(dragContainer)"
           (dragstart)="removeCssClass(dragContainer)"
           (dragend)="removeCssClass(dragContainer)">

        <div *ngIf="!!form.getRawValue().file" class="flex justify-end">
          <button mat-icon-button class="relative top-[-1rem] right-[-1rem] hover:text-red-900" style="z-index: 20;"
                  (click)="event.stopPropagation();event.preventDefault(); removeFile()"
                  *ngIf="!readonly">
            <mat-icon svgIcon="feather:trash-2" class=""></mat-icon>
          </button>
        </div>
        <input type="file" class="cursor-pointer" formControlName="file"
               class="text-sm  w-full placeholder:text-gray-500 form-control "
               (change)="fileBrowseHandler($event.target.files)">
        <div class="flex flex-col self-center items-center  ">

          <ng-container *ngIf="!form.getRawValue().fileSource; else iconFile">
            <div class="w-full  flex flex-col   justify-content-center items-center mt-12 ">
              <div>
                <mat-icon class=" scale-[200%] text-gray-600"
                          svgIcon="mat_outline:cloud_upload">
                </mat-icon>
              </div>
              <div>Clicca o trascina un file</div>
            </div>
          </ng-container>


          <ng-template #iconFile>
            <div class="relative">
              <mat-icon style="width: 75px; height: 75px" id="1234">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" fit="" height="100%" width="100%"
                     preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z">
                  </path>
                </svg>
              </mat-icon>
              <div class="tag px-1.5 rounded text-sm font-semibold leading-5 text-white"
                   [style.background-color]="getExtensionColor(form.getRawValue().fileSource?.name.split('.').pop())">
                {{ form.getRawValue().fileSource?.name.split('.').pop()| uppercase }}
              </div>
            </div>
            <div class="truncate max-w-50 ">{{
                form.getRawValue().fileSource?.name.split('.').slice(0,
                  -1).join('.') | titlecase
              }}
            </div>

          </ng-template>
        </div>
      </div>
    </div>

    <!--    <div class="" *ngIf="docExpiring">-->
    <!--      <mat-formData-field class="rounded fuse-mat-dense w-full">-->
    <!--        <mat-label>Scadenza</mat-label>-->
    <!--        <input matInput [matDatepicker]="pickerExp" formControlName="expiration"           -->
    <!--               class="text-sm  w-full f placeholder:text-gray-500 formData-control">-->
    <!--        <mat-datepicker-toggle matIconSuffix [for]="pickerExp"></mat-datepicker-toggle>-->
    <!--        <mat-datepicker #pickerExp></mat-datepicker>-->
    <!--        <mat-icon matPrefix class="cursor-pointer" svgIcon="clear" (click)="clearExpirationDate()">-->
    <!--        </mat-icon>-->
    <!--      </mat-formData-field>-->
    <!--    </div>-->
    <div class="mt-4 text-lg font-medium">Descrizione</div>
    <div class=" mt-2 ">

                <textarea formControlName="description"
                          class="text-sm  w-full f placeholder:text-gray-500 form-control"
                          placeholder="Inserisci una descrizione" rows="6">

                </textarea>
    </div>
    <div class="grid grid-cols-2 gap-4 w-full mt-4">
      <button class="btn btn-light cursor-pointer"
              (click)="cancel()">
                <span class="mat-button-wrapper">
                    Indietro
                </span>

      </button>

      <button class="btn btn-primary cursor-pointer flex"
              [loading]="uploading|async"
              (click)="save()" [disabled]="!form.valid || readonly ">
        <fa-icon [icon]="faSave" class="mr-2  "></fa-icon>
        Salva

      </button>
      {{ form.errors }}
    </div>
  </div>
</ng-template>


<ng-template #directoryBox let-parentDir="parentDir">
  <div><b>Cartelle</b></div>
  <div class="flex flex-row space-x-6 flex-wrap" *ngIf="directories|async as dirList; else waiting">
    <ng-container *ngFor="let dir of dirList ">
      <ng-container *ngTemplateOutlet="directoryElem; context:{dir: dir}"></ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="newDirElem;context:{parentDir:parentDir}"></ng-container>

  </div>
</ng-template>

<ng-template #directoryElem let-dir="dir">

  <div class="relative w-40 h-40 m-2 p-4 shadow rounded-2xl bg-card cursor-pointer" (click)="openDir(dir)">
    <div
      class="absolute z-20 top-1.5 right-1.5 w-8 h-8 min-h-8 mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base">
      <!--        <mat-icon role="img" class="mat-icon notranslate icon-size-5 mat-icon-no-color" aria-hidden="true"-->
      <!--                  data-mat-icon-type="svg" data-mat-icon-name="information-circle"-->
      <!--                  data-mat-icon-namespace="heroicons_solid">-->
      <!--            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" fit=""-->
      <!--                 height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">-->
      <!--                <path fill-rule="evenodd"-->
      <!--                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"-->
      <!--                      clip-rule="evenodd"></path>-->
      <!--            </svg>-->
      <!--        </mat-icon>-->

    </div>
    <div class="z-10 absolute inset-0 flex flex-col p-4 ">
      <div class="aspect-[9/6] ">

        <div class="flex items-center justify-center h-full">

          <mat-icon style="height: 45px; width: 60px">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                 aria-hidden="true" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet"
                 focusable="false">
              <path
                d="M19.5 21a3 3 0 003-3v-4.5a3 3 0 00-3-3h-15a3 3 0 00-3 3V18a3 3 0 003 3h15zM1.5 10.146V6a3 3 0 013-3h5.379a2.25 2.25 0 011.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 013 3v1.146A4.483 4.483 0 0019.5 9h-15a4.483 4.483 0 00-3 1.146z">
              </path>
            </svg>
          </mat-icon>
        </div>
      </div>
      <div class="flex flex-col flex-auto justify-center text-center text-sm font-medium">
        <div class="mat-mdc-tooltip-trigger" [matTooltip]="dir.name" [matTooltipDisabled]="dir.name?.length<20">
          {{ dir.name|titlecase }}
        </div><!---->
        <!--            <ng-container *ngLet="countFiles(dir)| async as count">-->
        <!--                <div class="text-secondary truncate">{{count > 1 ? count + ' files' : count + ' file'}} </div>-->
        <!--            </ng-container>-->
      </div>
    </div>
  </div>

</ng-template>


<ng-template #newDirElem let-parentDir="parentDir">

  <div class="relative w-40 h-40 m-2 p-4 hover:shadow rounded-2xl bg-card bg-white cursor-pointer">
    <!--        <a mat-button-ripple-uninitialized="" mat-icon-button=""-->
    <!--           class="absolute z-20 top-1.5 right-1.5 w-8 h-8 min-h-8 mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base"-->
    <!--           href="/apps/file-manager/details/cd6897cb-acfd-4016-8b53-3f66a5b5fc68" aria-disabled="false"><span-->
    <!--                class="mat-mdc-button-persistent-ripple "></span>-->
    <!--            <mat-icon role="img" class="mat-icon notranslate icon-size-5 mat-icon-no-color" aria-hidden="true"-->
    <!--                      data-mat-icon-type="svg" data-mat-icon-name="information-circle"-->
    <!--                      data-mat-icon-namespace="heroicons_solid">-->

    <!--            </mat-icon>-->
    <!--            <span class="mdc-button__label"></span><span class="mat-mdc-focus-indicator"></span><span-->
    <!--                    class="mat-mdc-button-touch-target"></span></a>-->
    <div class="z-10 absolute inset-0 flex flex-col p-1 cursor-pointer  ">
      <div class="aspect-[9/6] ">
        <div class="flex items-center justify-center h-full">

          <div class="relative">
            <mat-icon style="height: 45px; width: 60px">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                   aria-hidden="true" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet"
                   focusable="false">
                <path
                  d="M19.5 21a3 3 0 003-3v-4.5a3 3 0 00-3-3h-15a3 3 0 00-3 3V18a3 3 0 003 3h15zM1.5 10.146V6a3 3 0 013-3h5.379a2.25 2.25 0 011.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 013 3v1.146A4.483 4.483 0 0019.5 9h-15a4.483 4.483 0 00-3 1.146z">
                </path>
              </svg>
            </mat-icon>
            <div class="absolute z-10 bottom-0 bg-gray-200 rounded-full right-0 w-6 h-6">
              <mat-icon svgIcon="heroicons_solid:plus"></mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row flex-auto justify-center text-center text-sm font-medium items-center mb-3">
        <div contenteditable="true" #dirName (keydown)="clearPlaceholder($event, dirName)"
             class="rounded border-1   border-solid border-slate-400 bg-slate-200  px-1 "
             (focusout)="setPlaceholder($event, dirName)"
             (keyup.enter)="addDirectory(parentDir, $event.target)">
          {{ placeholderFolder }}
        </div>
        <div>
          <button mat-icon-button (click)="addDirectory(parentDir, dirName)"
                  style="width: 28px;    height: 28px;    padding: 0px;">
            <mat-icon svgIcon="feather:plus-circle" style="scale: 0.75!important;"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

</ng-template>


<ng-template #waiting>
  <app-spinner></app-spinner>
</ng-template>
