import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from "@angular/forms";
import {AttachmentDTO, BoatDTO, BoatTypeDTO, CountryDTO, ShipOwnerDTO} from "../../service/dto";
import {faImages, faUpload} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-boat-data-form',
  templateUrl: './boat-data-form.component.html',
  styleUrl: './boat-data-form.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BoatDataFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BoatDataFormComponent),
      multi: true
    }
  ]
})
export class BoatDataFormComponent implements ControlValueAccessor, Validator {
  form: FormGroup
  boatImg: AttachmentDTO;
  @Output()
  onFormUpdated: EventEmitter<any> = new EventEmitter();
  @Output()
  onFileUpdated = new EventEmitter<File>();

  @Input()
  boat: BoatDTO
  @Input()
  boatTypesList: BoatTypeDTO[];
  @Input()
  countryList: CountryDTO[];

  constructor(private fb: FormBuilder,) {
    this.form = this.fb.group({
      name: [undefined, Validators.required],
      beam: undefined,
      description: undefined,
      draft: undefined,
      length: undefined,
      boatModel: undefined,
      plateNumber: undefined,
      flag: undefined,
      boatType: [undefined, Validators.required],
      maxPassengers: undefined,
      registryNumber: undefined
    });
  }

  onTouched: () => void = () => {
  };

  writeValue(boat: BoatDTO): void {
    if (boat) {
      this.form.patchValue({
        name: boat.name,
        beam: boat.beam,
        description: boat.description,
        draft: boat.draft,
        length: boat.length,
        boatModel: boat.boatModel,
        plateNumber: boat.plateNumber,
        flag: boat.flag,
        boatType: boat.type,
        maxPassengers: boat.maxPassengers,
        registryNumber: boat.registryNumber,
      });

      if (boat.boatImages?.length > 0) {
        this.boatImg = boat.boatImages[0]
      }

      this.onFormUpdated.emit(boat);
    }
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }
    return {invalidForm: {valid: false, message: 'data are not valid'}};
  }

  isNew() {
    return !this.boat?.id;
  }

  upload(inputFile: HTMLInputElement) {
    if(this.isNew())
      return
    inputFile.click()
  }

  onUploadFile(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;

    const file: File = fileList?.[0]
    if (!file)
      return
    this.onFileUpdated.emit(file)
  }

  protected readonly faImages = faImages;
  protected readonly faUpload = faUpload;

  isRequiredField(field: string) {
    const form_field = this.form.get(field);
    if (!form_field.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }
}
