import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {currency, EURO} from "../../utils/utils";
import {ConfigService} from "../../service/config.service";

@Component({
  selector: 'app-switch-language',
  templateUrl: './switch-language.component.html',
  styleUrl: './switch-language.component.scss'
})
export class SwitchLanguageComponent {
  languages = [
    {name: 'italian', iso2: 'it', flag: '/assets/flags/it.svg', lang: 'it-IT'},
    {name: 'English', iso2: 'en', flag: '/assets/flags/uk.svg', lang: 'en-US'}
  ];
  selectedLanguage: { name: string, iso2: string, flag: string, lang: string };

  constructor(private translateService: TranslateService,
              private configService: ConfigService) {
    const browserLang = this.translateService.getBrowserLang();
    const defLang = this.translateService.getDefaultLang();
    let language;
    const savedLanguage = configService.selectedLanguage;

    if(savedLanguage) {
      language = this.languages.find(x => x.iso2 == savedLanguage);
    }else if (browserLang) {
      language = this.languages.find(x => x.iso2 == browserLang);
    } else {
      language = this.languages.find(x => x.iso2 == defLang);
    }
    if (!language) {
      language = {name: 'Inglese', iso2: 'en', flag: 'fi-en', lang: 'en-US'};
    }
    this.selectedLanguage = language;
    this.translateService.use(this.selectedLanguage.iso2)
  }

  changeLanguage() {
    // this.translateService.use(this.selectedLanguage.iso2)
    this.configService.selectedLanguage = this.selectedLanguage.iso2
    window.location.reload()
  }

  protected readonly currency = currency;
  protected readonly currencyType = EURO;
}
