import {Component, Input} from '@angular/core';
import {ReservationRequestDTO} from "../../service/dto";
import {faBan, faCheckCircle, faCircleXmark, faGears, faHourglassHalf, faLock} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-request-status-icon',
  templateUrl: './request-status-icon.component.html',
  styleUrl: './request-status-icon.component.scss'
})
export class RequestStatusIconComponent {

  @Input()
  request: ReservationRequestDTO;

  constructor() {
  }

  protected readonly faBan = faBan;
  protected readonly faCheckCircle = faCheckCircle;
  protected readonly faGears = faGears;
  protected readonly faCircleXmark = faCircleXmark;
  protected readonly faHourglassHalf = faHourglassHalf;
  protected readonly faLock = faLock;
}
