<div class="flex flex-row space-x-1 items-center  group transition-all w-10 data-[open=true]:w-full"
     [attr.data-open]="open">
  <div class="flex-grow">
    <ng-container *ngTemplateOutlet="searchBox"></ng-container>
  </div>

  <button mat-icon-button (click)="toggleOpen()" [matTooltip]="tooltip">
    <mat-icon [svgIcon]="!open ? 'feather:search': 'mat_outline:arrow_back'"
              class="scale-75 "></mat-icon>
  </button>
</div>


<ng-template #searchBox>
  <div
    class="flex items-center space-x-1  w-auto max-w-0 overflow-x-hidden transition  duration-500 group-data-[open=true]:max-w-full">
    <div class="relative w-full mt-[-1rem]">
      <div><label>{{ fieldLabel }}</label></div>
      <ng-select (focus)="searchObs.next('')"
                 appendTo="body"
                 [items]="data"
                 (clear)="onClearData()"
                 [typeahead]="searchObs"
                 [placeholder]="placeholder"
                 [bindLabel]="bindLabel"
                 [(ngModel)]="_selectedData">
      </ng-select>
    </div>
    <button mat-icon-button (click)="updateData()">
      <mat-icon svgIcon="feather:save"
                class="scale-75 "></mat-icon>
    </button>
  </div>
</ng-template>
