/** OPERATIONS  */
import moment from 'moment'

export type OpType =
  '=='   //equal
  | '!='  // not equal
  | '>'    //greater than
  | '>='  //greater than or equal
  | '=in='   //in
  | '=out='   // not in
  | '=isnull='  //is null
  | '=notnull='  //not null
  | '=bt='    //between
  | '=nb='    //not between
  | '<'    //less than
  | '<='    //less than or equal
  | '=like='   //contains
  | '=notlike='  // not cotain
  | '*=='  // start with
  | '==*'  // end with
  | '=ilike='  //containes ignore case
  | '=inotlike=';  //not contains ignore case

export type JsonOpType = '_contains';
export type JunctionType = ';' | ',';


/** STANDARD OPERATIONS */

export class GenericOperation implements Operation {
  field: string
  type: OpType
  value?: string | number | string[] | number[] | Date | Date[] | boolean

  constructor(field: string, opType: OpType, value?: string | number | string[] | number[] | Date | Date[] | boolean) {
    this.field = field
    this.type = opType
    this.value = value;
  }

  visit(): string {
    let obj = this.field + this.type + (this.value ?? '')
    return obj
  }
}

export class Like extends GenericOperation {

  constructor(field: string, value?: string | number | string[] | number[]) {
    super(field, '=like=', value)
  }
}

export class ILike extends GenericOperation {

  constructor(field: string, value?: string | number | string[] | number[]) {
    super(field, '=ilike=', value)
  }
}

export class Between extends GenericOperation {
  constructor(field: string, value: string[] | number[] | Date[]) {
    super(field, '=bt=', value)
  }

  override visit(): string {
    if (!this.value) {
      return ''
    }
    const tmpArray = this.value as Array<any>;
    let val = '('
    tmpArray.forEach((v, idx) => {
      val += v + ','
    })
    val = val.slice(0, val.length - 1)
    val += ')'
    let obj = this.field + this.type + val
    return obj
  }

}

export class NotBetween extends GenericOperation {
  constructor(field: string, value?: string | number | Date) {
    super(field, '=nb=', value)
  }

  override visit(): string {
    if (!this.value) {
      return ''
    }
    const tmpArray = this.value as Array<any>;
    let val = '('
    tmpArray.forEach((v, idx) => {
      val += v + ''
    })
    val = val.slice(0, val.length - 1)
    val += ')'
    let obj = this.field + this.type + val
    return obj
  }
}

export class Gte extends GenericOperation {
  constructor(field: string, value?: string | number | Date) {
    super(field, '>=', value)
  }
}


export class StartWith extends GenericOperation {
  constructor(field: string, value?: string) {
    super(field, '*==', value)
  }
}


export class EndWith extends GenericOperation {
  constructor(field: string, value?: string) {
    super(field, '==*', value)
  }
}

export class Gt extends GenericOperation {
  constructor(field: string, value?: string | number | Date) {
    super(field, '>', value)
  }
}

export class Lte extends GenericOperation {
  constructor(field: string, value?: string | number | Date) {
    super(field, '<=', value)
  }
}

export class Lt extends GenericOperation {
  constructor(field: string, value?: string | number | Date) {
    super(field, '<', value)
  }
}

export class Eq extends GenericOperation {

  constructor(field: string, value?: string | number | string[] | number[] | boolean) {
    super(field, '==', value)
  }
}

export class NotEq extends GenericOperation {

  constructor(field: string, value?: string | number | string[] | number[] | boolean) {
    super(field, '!=', value)
  }
}

export class NotIn extends GenericOperation {

  constructor(field: string, value: string[] | number[]) {
    super(field, '=out=', value)
  }

  override visit(): string {
    if (!this.value) {
      return ''
    }
    const tmpArray = this.value as Array<any>;
    let val = '('
    tmpArray.forEach((v, idx) => {
      val += v + ''
    })
    val = val.slice(0, val.length - 1)
    val += ')'
    let obj = this.field + this.type + val
    return obj
  }

}

export class In extends GenericOperation {

  constructor(field: string, value: string[] | number[]) {
    super(field, '=in=', value)
  }

  override visit(): string {
    if (!this.value) {
      return ''
    }
    const tmpArray = this.value as Array<any>;
    let val = '('
    tmpArray.forEach((v, idx) => {
      val += v + ','
    })
    val = val.slice(0, val.length - 1)
    val += ')'
    let obj = this.field + this.type + val
    return obj
  }
}

export class IsNull extends GenericOperation {

  constructor(field: string) {
    super(field, '=isnull=', "''")
  }


}

export class IsNotNull extends GenericOperation {

  constructor(field: string) {
    super(field, '=notnull=', '')
  }
}

// /** JSON OPERATIONS */
// export class JsonOperations implements BoolOperation {
//   field: string
//   type: JsonOpType
//   value?: string | number | string[] | number[] | Date | boolean | Object
//
//   constructor(field: string, opType: JsonOpType, value?: string | number | string[] | number[] | Date | boolean | Object) {
//     this.field = field
//     this.type = opType
//     this.value = value;
//   }
//
//   visit(): Object {
//     let obj = {}
//     if (this.field.indexOf('.') < 0) {
//       // @ts-ignore
//       obj[this.field] = {}
//       // @ts-ignore
//       obj[this.field][this.type] = this.value
//
//     } else {
//       const split = this.field.split('.')
//       let tmp = obj;
//       for (let p of split) {
//         // @ts-ignore
//         tmp[p] = {}
//         // @ts-ignore
//         tmp = tmp[p]
//       }
//       // @ts-ignore
//       tmp[this.type] = this.value
//
//     }
//     return obj
//   }
// }

// export class JsonContains extends JsonOperations {
//   constructor(field: string, value?: Object) {
//     super(field, '_contains', value)
//   }
//
// }


export interface BoolOperation {
  visit(): string
}

export interface Operation extends BoolOperation {
  field: string,
  type: OpType
  value?: string | number | string[] | number[] | Date | Date[] | boolean
}

export abstract class Junction implements BoolOperation {
  type: JunctionType | undefined
  operations: BoolOperation[] = []

  visit(): string {
    let obj = ''
    if (this.operations?.length == 0)
      return;
    obj += '('
    this.operations.forEach((value, index) => {
      obj += value.visit() + (index < this.operations.length - 1 ? this.type : '')
    });

    obj += ')'
    return obj
  }
}

export abstract class BaseJunction extends Junction {
  add(op: Operation) {
    this.operations.push(op)
    return this
  }

  like(field: string, value: string) {
    this.operations.push(new Like(field, value))
    return this
  }

  ilike(field: string, value: string) {
    this.operations.push(new ILike(field, value))
    return this
  }

  eq(field: string, value: string | number | boolean) {
    this.operations.push(new Eq(field, value))
    return this
  }

  notEq(field: string, value: string | number | boolean) {
    this.operations.push(new NotEq(field, value))
    return this
  }

  in(field: string, value: string[] | number[]) {
    this.operations.push(new In(field, value))
    return this
  }

  notIn(field: string, value: string[] | number[]) {
    this.operations.push(new NotIn(field, value))
    return this
  }

  isNull(field: string) {
    this.operations.push(new IsNull(field))
    return this
  }

  isNotNull(field: string) {
    this.operations.push(new IsNotNull(field))
    return this
  }

  gte(field: string, value: string | Date) {
    this.operations.push(new Gte(field, value))
    return this
  }

  gt(field: string, value: string | number | Date) {
    this.operations.push(new Gt(field, value))
    return this
  }

  lte(field: string, value: string | number | Date) {
    this.operations.push(new Lte(field, value))
    return this
  }

  lt(field: string, value: string | number | Date) {
    this.operations.push(new Lt(field, value))
    return this
  }


  btw(field: string, value: string[] | number[]) {
    if (value.length != 2)
      throw Error("In between filter you mast pass 2 values. Passed " + value.length)
    this.operations.push(new Between(field, value))
    return this
  }

  btwDates(field: string, value: Date[]) {
    if (value.length != 2)
      throw Error("In between filter you mast pass 2 values. Passed " + value.length)
    this.operations.push(new Gte(field, moment(value[0]).toISOString()))
    this.operations.push(new Lte(field, moment(value[1]).toISOString()))
    return this
  }

  startWith(field: string, value: string) {
    this.operations.push(new StartWith(field, value))
    return this
  }

  endWith(field: string, value: string) {
    this.operations.push(new EndWith(field, value))
    return this
  }

  // jsonContains(field: string, value: Object) {
  //   this.operations.push(new JsonContains(field, value))
  //   return this
  // }

  or() {
    const op = new or();
    this.operations.push(op)
    return op
  }

  and() {
    const op = new and();
    this.operations.push(op)
    return op
  }

  append(op: BaseJunction){
    this.operations.push(op)
    return this
  }
  appendAll(op: BaseJunction[]){
    this.operations.push(...op)
    return this
  }

}

export class and extends BaseJunction {
  override type: JunctionType = ';' as JunctionType
}

export class or extends BaseJunction {
  override type: JunctionType = ',' as JunctionType
}

export class ExpressionBuilder {

  static getBuilder() {
    return new ExpressionBuilder();
  }

  static toGql(op: BoolOperation | undefined): string {
    if (!op)
      return ''
    return op.visit()
  }

  root() {
    return new and();
  }

  and() {
    return new and();
  }

  or() {
    return new or();
  }

}
