<div class="flex space-x-2 items-center">
  <div class="w-6 h-6 rounded-full cursor-pointer transition duration-300 brightness-75 hover:brightness-95"
       [matMenuTriggerFor]="menu"
       [matTooltip]="'common.changeLang'|translate"
       [ngStyle]="{'background-image': 'url('+this.selectedLanguage?.flag+')'}"></div>
</div>

<mat-menu #menu="matMenu">
  <button class="flex space-x-2" mat-menu-item [matTooltip]="currencyType.name"
          matTooltipShowDelay="1000" matTooltipPosition="left" (click)="selectedLanguage = lang; changeLanguage()"
          *ngFor="let lang of languages">
    <div class="flex space-x-2 text-sm">
      <div class="w-6 h-6 rounded-full cursor-pointer"
           [matTooltip]="'common.changeLang'|translate"
           [ngStyle]="{'background-image': 'url('+lang?.flag+')'}">
      </div>
      <div> {{ lang.name | titlecase }}</div>
    </div>
  </button>

</mat-menu>
