<div [formGroup]="form" class="space-y-3 text-sm">

  <div class="grid grid-cols-4 gap-3">

    <div class="relative">
      <label>Data creazione</label>
      <div class="input-group">
        <input
          class="form-control pointer-events-none"
          [placeholder]="'common.date-format' | translate"
          formControlName="createdAt"
          ngbDatepicker
          container="body"
          disabled="true"
          #dpCreatedAt="ngbDatepicker"
        />
        <button class="btn btn-outline-secondary " [disabled]="true" (click)="dpCreatedAt.toggle()">
          <fa-icon [icon]="faCalendarAlt"></fa-icon>
        </button>
      </div>
    </div>
    <div>
      <div><label>Porto *</label></div>
      <ng-select (focus)="port$.next('')"
                 [items]="portList"
                 [typeahead]="port$"
                 [readonly]="true"
                 appendTo="body"
                 bindLabel="name"
                 formControlName="port">
      </ng-select>
    </div>
    <div class="relative" *ngIf="form.get('berthCode').value">
      <div><label>Posto barca</label></div>
      <ng-select (focus)="berths$.next('')"
                 [items]="berthList"
                 [typeahead]="berths$"
                 appendTo="body"
                 bindLabel="code"
                 [readonly]="true"
                 formControlName="berthCode">
      </ng-select>
    </div>
    <div class="relative">
      <div><label>Scalo</label></div>
      <ng-select appearance="outline"
                 [searchable]="false"
                 appendTo="body"
                 [clearable]="false"
                 formControlName="transit">
        <ng-option [value]="false">
          Scalo
        </ng-option>
        <ng-option [value]="true">
          Transito
        </ng-option>

      </ng-select>
    </div>
    <div class="relative">
      <div><label>Attracco</label></div>
      <ng-select (focus)="docking$.next('')"
                 [items]="dockingList"
                 [typeahead]="docking$"
                 appendTo="body"
                 bindLabel="name"
                 formControlName="docking">
      </ng-select>
    </div>


    <div>
      <div class="flex flex-col relative">
        <label>Data arrivo *</label>

        <div class="input-group">
          <input
            class="form-control"
            [placeholder]="'common.date-format' | translate"
            name="dp"
            formControlName="arrivalDate"
            ngbDatepicker
            container="body"
            [minDate]="minDate"
            [maxDate]="maxDate"
            #d="ngbDatepicker"
          />
          <button class="btn btn-outline-secondary " [disabled]="form.disabled" (click)="d.toggle()">
            <fa-icon [icon]="faCalendarAlt"></fa-icon>
          </button>
        </div>
      </div>
    </div>
    <div>
      <div class="flex flex-col relative">
        <label>Data partenza <span
          *ngIf="this.form.get('departureDate').hasValidator(Validators.required)">*</span></label>

        <div class="input-group">
          <input
            class="form-control"
            [placeholder]="'common.date-format' | translate"
            name="dp2"
            formControlName="departureDate"
            ngbDatepicker
            container="body"
            [minDate]="minDate"
            [maxDate]="maxDate"
            #d2="ngbDatepicker"
          />
          <button class="btn btn-outline-secondary " [disabled]="form.disabled||this.form.get('transit').value"
                  (click)="d2.toggle()">
            <fa-icon [icon]="faCalendarAlt"></fa-icon>
          </button>
        </div>
      </div>

    </div>
    <div class="grid grid-cols-3 gap-x-3">

      <div class="col-span-3  mt-[-0.25rem] text-sm text-center">
        <span>Dimensioni barca</span>
      </div>
      <div class=" mt-[-0.5rem]">
        <label class="text-xs">Lung. <span *ngIf="isRequiredField('length')">*</span></label>
        <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control control-small"
               formControlName="length" min="0" type="number"/>

      </div>
      <div class=" mt-[-0.5rem]">
        <label class="text-xs">Larg. <span *ngIf="isRequiredField('beam')">*</span></label>
        <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control control-small"
               formControlName="beam" min="0" type="number"/>

      </div>
      <div class=" mt-[-0.5rem]">
        <label class="text-xs">Pesc. <span *ngIf="isRequiredField('draft')">*</span></label>
        <input class="pl-3  text-sm  w-full f placeholder:text-gray-500 form-control control-small"
               formControlName="draft"
               min="0"
               type="number"/>

      </div>
    </div>
    <div *ngIf=" form.get('price').value">
      <b>Prezzo:</b> {{ form.get('price').value }} {{ getCurrency() }}
    </div>
    <div></div>
    <div></div>
    <div *ngIf="reservationRequest?.expirationDate">
      <b>Scadenza:</b> {{ reservationRequest?.expirationDate | localizeDate }}
    </div>
  </div>
</div>
