import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AttachmentDTO} from "../../../service/dto";

@Component({
  selector: 'app-remove-file-modal',
  templateUrl: './remove-file-modal.component.html',
  styleUrl: './remove-file-modal.component.scss'
})
export class RemoveFileModalComponent {
  @Output()
  remove$ = new EventEmitter<string>();
  constructor(private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: { attachment: AttachmentDTO },
              public dialogRef: MatDialogRef<RemoveFileModalComponent>,) {


  }

  close() {
    this.dialogRef.close(null)
  }


  remove(){
    this.remove$.emit('confirmed')
  }
}
