import {Apollo, gql, MutationResult} from "apollo-angular";
import {Injectable, OnDestroy} from "@angular/core";
import {combineLatest, filter, map, Observable, Subject, takeUntil} from "rxjs";
import {BoolOperation, ExpressionBuilder} from "./ExpressionBuilder";
import {InsuranceDataDTO} from "./dto";

export const INSURANCE_DATA_FIELD = gql`
  fragment INSURANCE_DATA_FIELD on InsuranceDataDTO {
    id
    companyName
    insurancePremium
    insurancePolicyName
    expirationDate
    boatId
  }
`;


const add = gql`
  ${INSURANCE_DATA_FIELD}

  mutation AddInsuranceData($dto: InsuranceDataInput!) {
    insuranceData_add_one(body: $dto) {
      ...INSURANCE_DATA_FIELD
    }
  }`
const update = gql`
  ${INSURANCE_DATA_FIELD}

  mutation UpdateInsuranceData($id: Int!, $dto: InsuranceDataInput!) {
    insuranceData_update_one(pk_columns: {id: $id}, body: $dto){
      ...INSURANCE_DATA_FIELD

    }

  }`


const getAll = gql`
  ${INSURANCE_DATA_FIELD}

  query GetAllInsuranceData($pageNumber: Int, $pageDim: Int, $where:String) {
    insuranceData (pageNum: $pageNumber, pageDim: $pageDim, where: $where, sort: "id,desc") {
      ...INSURANCE_DATA_FIELD

    }
  }
`;

const getById = gql`
  ${INSURANCE_DATA_FIELD}

  query GetBerthById($id: ID!) {
    insuranceData_by_pk(id: $id) {
      ...INSURANCE_DATA_FIELD

    }
  }`;


const count = gql`query CountInsuranceData( $where: String ) {
  insuranceData_aggregate(aggregation: {count: {field: "id", distinct: false}}, where: $where) {
    count
  }
}`;


@Injectable({
  providedIn: 'root'
})
export class InsuranceDataService implements OnDestroy {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apollo: Apollo) {

  }

  getAll(page: number = 0, pageSize: number = 10, filters?: BoolOperation): Observable<{
    data: InsuranceDataDTO[],
    totalRows: number
  }> {
    const fetchData = this.apollo
      .watchQuery({
        query: getAll,
        variables: {
          pageNumber: page,
          pageDim: pageSize,
          where: ExpressionBuilder.toGql(filters),
        },
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    const fetchCount = this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    return combineLatest([fetchData, fetchCount]).pipe(
      takeUntil(this.destroy$),
      filter(x => !!x[0].data),
      map(x => {
        // @ts-ignore
        const aggRes = x[1].data['insuranceData_aggregate']
        // @ts-ignore
        const data = x[0].data?.['insuranceData']
        const d = data.map((y: any) => (({__typename, ...o}) => o)(y));
        return {
          data: d,
          totalRows: aggRes.count
        }
      })
    )
  }

  getById(id: number, includes?: { port: boolean }): Observable<InsuranceDataDTO> {
    const inc = {
      ...{port: false},
      ...includes
    }
    return this.apollo.watchQuery({
      query: getById,
      variables: {
        id: id,
        includePort: inc.port
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      filter(x => !x.loading),
      takeUntil(this.destroy$),
      filter(x => !x.loading),
      map(x => {
          // @ts-ignore
          const data = x.data?.['insuranceData_by_pk'];
          const d = (({__typename, ...o}) => o)(data)
          return d
        }
      )
    );
  }

  count(filters?: BoolOperation): Observable<number> {
    return this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(
        filter(c => !c.loading),
        map(x => {
            // @ts-ignore
            const aggRes = x.data['insuranceData_aggregate']
            return aggRes.count
          }
        )
      )
  }

  add(dto: InsuranceDataDTO): Observable<InsuranceDataDTO> {
    return this.apollo.mutate({
      mutation: add,
      variables: {dto: dto},
      fetchPolicy: 'no-cache',
    }).pipe(map((x: MutationResult) => x.data.insert_insuranceData_one))
  }

  update(id: number, dto: InsuranceDataDTO): Observable<InsuranceDataDTO> {
    return this.apollo.mutate({
      mutation: update,
      variables: {id: id, dto: dto},
      fetchPolicy: 'no-cache',

    }).pipe(map((x: MutationResult) => x.data.update_insuranceData_by_pk))
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
