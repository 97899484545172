import {Injectable, OnDestroy} from '@angular/core';
import {combineLatest, filter, map, Observable, Subject, takeUntil} from "rxjs";

import {Apollo, gql, MutationResult} from "apollo-angular";
import {BoolOperation, ExpressionBuilder} from "./ExpressionBuilder";
import {PortDTO} from "./dto";
import {BERTH_FIELD} from "./berth.service";

export const PORT_FIELD = gql`
  fragment PORT_FIELD on PortDTO {
    id
    name
    bgImageUrl
    mapUrl
    maxLenght
    draft
    vhfChannel
  }
`;


const add = gql`
  ${PORT_FIELD}

  mutation AddPort($dto: PortDTO!) {
    port_add_one(body: $dto) {
      ...PORT_FIELD
    }
  }`
const update = gql`
  ${PORT_FIELD}

  mutation UpdatePort($id: Int!, $dto: PortDTO!) {
    port_update_one(id: {id: $id}, body: $dto){
      ...PORT_FIELD

    }

  }`


const getAll = gql`
  ${PORT_FIELD}
  ${BERTH_FIELD}

  query GetAllPorts($pageNumber: Int, $pageDim: Int, $where:String, $sort: String = "id,desc",$includeBerths: Boolean = false ) {
    port(pageNum: $pageNumber, pageDim: $pageDim, where: $where, sort: $sort) {
      ...PORT_FIELD
      berths @include(if: $includeBerths) {
        ...BERTH_FIELD
      }
    }
  }
`;

const getById = gql`
  ${PORT_FIELD}
  ${BERTH_FIELD}

  query GetPortById($id: ID!, $includeBerths: Boolean = false) {
    port_by_pk(id: $id) {
      ...PORT_FIELD
      berths @include(if: $includeBerths) {
        ...BERTH_FIELD
      }
    }
  }`;


const count = gql`query CountPort( $where: String ) {
  port_aggregate(aggregation: {count: {field: "id", distinct: false}}, where: $where) {
    count
  }
}`;


const getStatistics = gql`

  query GetPortStat($where: String, $count:String, $max: [String], $min: [String] ) {
    port_aggregate(
      aggregation: {max: $max, count: {distinct: true, field: $count}, min: $min }
      where: $where
    ) {
      max
      count
      min
    }
  }`;


@Injectable({
  providedIn: 'root'
})
export class PortService implements OnDestroy {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apollo: Apollo) {

  }

  getAll(page: number = 0, pageSize: number = 10, filters?: BoolOperation, sort?: string,  includes: { berths: boolean } = {berths: false}): Observable<{
    data: PortDTO[],
    totalRows: number
  }> {
    const inc = {...{berths: false}, ...includes}

    const fetchData = this.apollo
      .watchQuery({
        query: getAll,
        variables: {
          pageNumber: page,
          pageDim: pageSize,
          sort: sort,
          where: ExpressionBuilder.toGql(filters),
          includeBerths: inc.berths

        },
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    const fetchCount = this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    return combineLatest([fetchData, fetchCount]).pipe(
      takeUntil(this.destroy$),
      filter(x => !!x[0].data),
      map(x => {
        // @ts-ignore
        const aggRes = x[1].data['port_aggregate']
        // @ts-ignore
        const data = x[0].data?.['port']
        const d = data.map((y: any) => (({__typename, ...o}) => o)(y));
        return {
          data: d,
          totalRows: aggRes.count
        }
      })
    )
  }

  getById(id: number, includes: { berths: boolean } = {berths: false}): Observable<PortDTO> {

    const inc = {...{berths: false}, ...includes}
    return this.apollo.watchQuery({
      query: getById,
      variables: {
        id: id,
        includeBerths: inc.berths
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      filter(x => !x.loading),
      takeUntil(this.destroy$),
      filter(x => !x.loading),
      map(x => {
          // @ts-ignore
          const data = x.data?.['port_by_pk'];
          const d = (({__typename, ...o}) => o)(data)
          return d
        }
      )
    );
  }

  //

  add(dto: PortDTO): Observable<PortDTO> {
    return this.apollo.mutate({
      mutation: add,
      variables: {dto: dto},
      fetchPolicy: 'no-cache',
    }).pipe(map((x: MutationResult) => x.data.insert_port_one))
  }

  update(id: number, dto: PortDTO): Observable<PortDTO> {
    return this.apollo.mutate({
      mutation: update,
      variables: {id: id, dto: dto},
      fetchPolicy: 'no-cache',

    }).pipe(map((x: MutationResult) => x.data.update_port_by_pk))
  }


  getStatistics(id: number): Observable<{ count: number, max: any, min: any }> {

    return this.apollo.watchQuery({
      query: getStatistics,
      variables: {
        where: ExpressionBuilder.toGql(ExpressionBuilder.getBuilder().root().eq('id', id)),
        min: ["berths.length"],
        max: ["berths.length"],
        count: "berths.id"
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      filter(x => !x.loading),
      takeUntil(this.destroy$),
      filter(x => !x.loading),
      map(x => {
          // @ts-ignore
          const data = x.data?.['port_aggregate'];
          const d = (({__typename, ...o}) => o)(data)
          return d
        }
      )
    );
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
