<div>
  <div class="dp-hidden position-absolute">
    <div class="input-group">
      <input
        #datepicker="ngbDatepicker"
        (dateSelect)="onDateSelection($event)"
        [autoClose]="'outside'"
        [dayTemplate]="t"
        [displayMonths]="2"
        [startDate]="fromDate!"
        [minDate]="minDate"
        [maxDate]="maxDate"
        class="form-control"
        name="datepicker"
        container='body'
        ngbDatepicker
        outsideDays="hidden"
        tabindex="-1"
      />
      <ng-template #t let-date let-focused="focused">
					<span
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
            [class.faded]="isHovered(date) || isInside(date)"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            class="custom-day"
          >
						{{ date.day }}
					</span>
      </ng-template>
    </div>
  </div>
  <div class="input-group">
    <input
      [disabled]="disabed"
      #dpFromDate
      (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
      [value]="formatter.format(fromDate)"
      class="form-control"
      name="dpFromDate"
      placeholder="dd-mm-yyyy"
    />
    <input
      [disabled]="disabed"
      #dpToDate
      (input)="toDate = validateInput(toDate, dpToDate.value)"
      [value]="formatter.format(toDate)"
      class="form-control"
      name="dpToDate"
      placeholder="dd-mm-yyyy"
    />
    <button (click)="datepicker.toggle()"
            [disabled]="disabed"
            style="height: 38px"
            class="btn btn-outline-secondary bi bi-calendar3 " type="button">
      <mat-icon svgIcon="feather:calendar"></mat-icon>
    </button>
  </div>

</div>

