import {Component, Input, OnInit} from '@angular/core';
import {
  distinctUntilChanged,
  filter,
  iif,
  mergeMap,
  Observable,
  of,
  ReplaySubject,
  Subject,
  takeUntil,
  tap
} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {catchError, debounceTime, map, switchMap} from "rxjs/operators";
import stc from "string-to-color";
import moment from "moment";
import {ExpressionBuilder} from "../../service/ExpressionBuilder";
import {AttachmentDirectoryDTO, AttachmentDTO, AttachmentType} from "../../service/dto";
import {AttachmentService} from "../../service/attachment.service";
import {AttachmentDirectoryService} from "../../service/attachment-directory.service";
import {faPlus, faSave} from "@fortawesome/free-solid-svg-icons";
import {formatBytes, indicate} from "../../utils/utils";
import {MatDialog} from "@angular/material/dialog";
import {RemoveFileModalComponent} from "./remove-file-modal/remove-file-modal.component";
import {TitleCasePipe} from "@angular/common";

export type Mode = 'FLAT' | 'DIRECTORY';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss'],
})
export class FileManagerComponent implements OnInit {
  protected destroy$: Subject<boolean> = new Subject<boolean>();
  uploading = new ReplaySubject<boolean>()
  @Input()
  docExpiring: boolean;
  @Input()
  rootDir: AttachmentDirectoryDTO;

  @Input()
  readonly: boolean;

  @Input()
  mode: Mode;

  @Input()
  type: AttachmentType;

  @Input()
  externalId: number;

  files: any[] = [];
  attachments = new ReplaySubject<number>();
  attachments$: Observable<AttachmentDTO[]>;
  sideNavMode = undefined;
  form: FormGroup;
  currentFile: AttachmentDTO = undefined;
  searchVal = undefined;
  filterForm: FormGroup
  directories$ = new ReplaySubject<number>();
  directories: Observable<AttachmentDirectoryDTO[]>;
  dirHistory: AttachmentDirectoryDTO[] = []
  offset = new Date().getTimezoneOffset();
  filenameEditable = false;

  constructor(private formBuilder: FormBuilder,
              private directoryService: AttachmentDirectoryService,
              private router: Router,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private toastService: ToastrService,
              private titleCasePipe: TitleCasePipe,
              private attachmentService: AttachmentService) {
    if (this.docExpiring === undefined)
      this.docExpiring = false
    this.filterForm = this.formBuilder.group({
      dateFrom: undefined,
      dateTo: undefined
    });
    this.directories = this.directories$.pipe(
      filter(x => !!this.getCurrentDir()?.id),
      map(x => {
        const exp = ExpressionBuilder.getBuilder().root();

        exp.eq('parentId', this.getCurrentDir().id);

        if (this.searchVal) {
          exp.ilike('name', '%' + this.searchVal + '%')
        }
        let dateFrom = this.filterForm.getRawValue().dateFrom
        let dateTo = this.filterForm.getRawValue().dateTo

        if (this.searchVal) {
          exp.ilike('files.name', '%' + this.searchVal + '%')
        }
        if (dateFrom && !dateTo) {
          dateFrom = dateFrom.setHours(0, 0, 0, 0)
          exp.gte('createdAt', moment(dateFrom).toISOString())

        } else if (!dateFrom && dateTo) {
          dateTo = dateTo.setHours(23, 59, 59, 999)
          exp.lte('createdAt', moment(dateTo).toISOString())

        } else if (!!dateFrom && !!dateTo) {
          dateFrom = dateFrom.setHours(0, 0, 0, 0)
          dateTo = dateTo.setHours(23, 59, 59, 999)
          exp.btw('createdAt', [
            moment(dateFrom).toISOString(),
            moment(dateTo).toISOString()])
        }
        return exp;
      }),
      switchMap(filters => this.directoryService.getAll(0, 1000, filters, "id,desc", {parent: false}).pipe(
          catchError((e) => {
            this.toastService.error('Error on fetch data')
            throw e;
          }),
          map(x => x.data)
        )
      )
    );
    this.filterForm.valueChanges.pipe(debounceTime(250), distinctUntilChanged()).subscribe(x => this.initData())

    this.attachments$ = this.attachments.pipe(
      filter(x => !!this.getCurrentDir()?.id),
      map(z => {
        const exp = ExpressionBuilder.getBuilder().root();

        if (this.getCurrentDir()?.id) {
          exp.eq('directoryId', this.getCurrentDir().id);
        } else {
          exp.isNull('directoryId')
        }
        let dateFrom = this.filterForm.getRawValue().dateFrom
        let dateTo = this.filterForm.getRawValue().dateTo

        if (this.searchVal) {
          exp.ilike('file.name', '%' + this.searchVal + '%')
        }
        if (dateFrom && !dateTo) {
          dateFrom = dateFrom.setHours(0, 0, 0, 0)
          exp.gte('file.createdAt', moment(dateFrom).toISOString())

        } else if (!dateFrom && dateTo) {
          dateTo = dateTo.setHours(23, 59, 59, 999)
          exp.lte('file.createdAt', moment(dateTo).toISOString())

        } else if (!!dateFrom && !!dateTo) {
          dateFrom = dateFrom.setHours(0, 0, 0, 0)
          dateTo = dateTo.setHours(23, 59, 59, 999)
          exp.btw('file.createdAt', [
            moment(dateFrom).toISOString(),
            moment(dateTo).toISOString()])
        }
        return exp
      }),
      switchMap(x => this.attachmentService.getAll(0, 5000, x).pipe(
          catchError((e) => {
            this.toastService.error('Error on fetch data')
            throw e;
          })
        )
      ),
      map(x => x.data),
    );

    this.form = this.formBuilder.group({
      file: undefined,
      fileSource: [undefined, Validators.required],
      description: '',
      expiration: undefined,

    });
    if (this.readonly) {
      this.form.disable()
    }
  }


  ngOnInit(): void {
    this.initData()
  }

  addNew(): void {
    this.clearForm()
    this.currentFile = undefined;
    this.filenameEditable = false;
    this.sideNavMode = 'NEW_FILE'
  }

  clearForm() {
    this.form.patchValue({
      file: undefined,
      fileSource: undefined,
      description: '',
      expiration: undefined
    })
  }

  save(): void {
    const desc = this.form.getRawValue().description;
    const expiration = this.form.getRawValue().expiration;
    this.attachmentService.uploadFile(this.form.getRawValue().fileSource, this.type, this.getCurrentDir()?.id, this.externalId, desc, expiration).pipe(
      indicate(this.uploading),

      takeUntil(this.destroy$),
      catchError(e => {
        this.toastService.error("Errore durante il recupero dei dati")
        throw e
      }),
    ).subscribe(x => {
        this.toastService.success("Upload completato");
        this.sideNavMode = undefined
        this.initData();

      }
    );


  }

  update(): void {

    const obs = this.attachmentService.update(this.currentFile.id,
      {
        ...this.currentFile,
        description: this.currentFile.description,
      } as AttachmentDTO
    );

    obs.pipe(
      catchError((e) => {

        this.toastService.error('Errore durante l\'aggiornamento')

        throw e;
      }),
    ).subscribe((x) => {
      this.initData();
      this.toastService.success('Allegato aggiornato')
      this.sideNavMode = undefined;
      this.filenameEditable = undefined;
    });
  }


  initData(): void {
    this.attachments.next(null);
    this.directories$.next(null);
  }


  cancel(): void {
    this.clearForm()
    this.currentFile = undefined;
    this.sideNavMode = undefined;
    this.filenameEditable = false;
  }


  /**
   * on file drop handler
   */
  onFileDropped($event) {
  }


  fileBrowseHandler(files) {

    if (!files)
      this.form.patchValue({
        file: undefined,
        fileSource: undefined
      })
    else
      this.form.patchValue({
        fileSource: files[0]
      })
  }


  deleteFile(index: number) {
    this.files.splice(index, 1);
  }


  getUnitMeasure(bytes: number, decimals: number = 0) {
    return formatBytes(bytes, decimals)
  }


  onFileOver($event: boolean) {
  }

  selectFile(file: AttachmentDTO) {
    if (!this.sideNavMode) {
      this.currentFile = file;
      this.sideNavMode = 'SHOW_DETAIL'
    } else {
      this.sideNavMode = undefined
    }
  }

  onFileSelected(file: File) {
    this.form.patchValue({
        fileSource: file
      }
    );
  }

  removeFile() {
    this.form.get('file').setValue(undefined)
    this.form.get('fileSource').setValue(undefined)
  }

  getExtensionColor(str: string) {
    if (!str)
      return '#000';
    const color = stc(str.toUpperCase())
    return color
  }

  // download(file: AttachmentDTO) {
  // this.attachmentService.downloadFile(file).pipe(
  //   takeUntil(this.destroy$),
  //   catchError(e => {
  //     this.toastService.error("Errore durante il download del file")
  //     throw e
  //   })
  // ).subscribe(x => this.downloadFile(x.body.body, file.name))
  // }

  downloadFile(data: Blob, filename: string) {
    const url = window.URL.createObjectURL(data);
    const anchor = document.createElement('a');
    // window.open(url.toString());anchor.download = filename;
    // anchor.download = filename;
    anchor.href = url;
    anchor.download = filename
    anchor.target = '_blank'
    anchor.click();
    setTimeout(() => anchor.remove(), 1000)
  }

  delete(currentFile: AttachmentDTO) {
    const ref = this.dialog.open(RemoveFileModalComponent, {
      minWidth: '45%',
      minHeight: '40%',
      data: {attachment: currentFile},
    });


    ref.componentInstance.remove$.subscribe((result) => {
      if (result == 'confirmed') {
        this.attachmentService.delete(this.currentFile.id).pipe(
          catchError(e => {
            this.toastService.error("Errore durante la cancellazione del file")
            throw e
          })
        ).subscribe(x => {
          this.sideNavMode = undefined;
          this.toastService.info("Allegato eliminato")
          this.initData();
          ref.close(null)

        })
      }
    });

  }


  search(event: string) {
    this.searchVal = event;
    this.initData()
  }

  clear(event: any) {
    this.searchVal = undefined;
    this.initData()
  }

  clearDates() {
    this.filterForm.reset({
      dateFrom: undefined,
      dateTo: undefined
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  clearExpirationDate() {
    this.form.get('expiration').setValue(null)
  }


  // countFiles(dir: AttachmentDirectoryDTO) {
  //   return this.directoryService.countFileInDirectory(dir.id)
  // }

  addDirectory(parentDir: AttachmentDirectoryDTO, target: HTMLElement) {
    if (!target.innerText || target.innerText?.length == 0 || target.innerText.trim().toLowerCase() == 'nuova cartella') {
      this.toastService.info("Digita il nome della cartella")
      return
    }
    const dir = {
      name: target.innerText?.replaceAll("\n", ""),
      parentId: this.getCurrentDir().id
    } as AttachmentDirectoryDTO
    this.directoryService.add(dir).pipe(
      catchError(e => {
        this.toastService.error("Errore durante la creazione della cartella")
        throw e;
      }),
    ).subscribe(x => {
      this.toastService.success("Cartella creata")
      target.innerText = this.placeholderFolder
      this.initData();
    })
  }

  openDir(dir: AttachmentDirectoryDTO) {
    this.addHistory(dir);
    this.initData()
  }

  goBack() {
    this.removeHistory(this.getCurrentDir());
    this.initData();
  }

  goHome() {
    this.clearHistory();
    this.initData();
  }


  addHistory(dir: AttachmentDirectoryDTO) {
    this.dirHistory.push(dir);
  }

  removeHistory(dir: AttachmentDirectoryDTO) {
    this.dirHistory = this.dirHistory.filter(x => x.id !== dir.id)
  }

  getCurrentDir(): AttachmentDirectoryDTO {
    if (this.dirHistory.length == 0) {
      return this.getRootDir();
    }
    return this.dirHistory[this.dirHistory.length - 1];
  }

  getPrevDir(): AttachmentDirectoryDTO {
    if (this.dirHistory.length <= 1) {
      return this.getRootDir();
    }
    return this.dirHistory[this.dirHistory.length - 2];
  }

  getRootDir() {
    return this.rootDir;
  }

  clearHistory() {
    this.dirHistory = []
  }

  getHistoryPath(): string {
    let path = this.dirHistory.reduce((a, v) => (a.endsWith('/') ? a : a + '/') + this.titleCasePipe.transform(v.name), '/root')
    return path;
  }


  isExpiring(file: AttachmentDTO) {
    if (!this.docExpiring) {
      return false
    }
    const twoWeeks = moment(file.expiration).add(-14, 'days')
    return moment().isAfter(twoWeeks) && moment().isBefore(file.expiration);
  }

  isExpired(file: AttachmentDTO) {
    if (!this.docExpiring) {
      return false
    }
    return moment().isAfter(file.expiration);
  }

  protected readonly AttachmentType = AttachmentType;


  editFileName() {
    this.filenameEditable = true
  }


  updateFileName() {
    this.attachmentService.update(this.currentFile.id, {name: this.currentFile.name} as AttachmentDTO).pipe(
      catchError(e => {
        this.toastService.error("Errore durante l'aggiornamento del nome")
        throw e;
      }),
    ).subscribe(x => {
      this.toastService.info("File rinominato")

    })
  }

  deleteDirectory(dir: AttachmentDirectoryDTO) {
    const exp = ExpressionBuilder.getBuilder().root().eq('directoryId', this.rootDir.id)
    const showError = () => of(false).pipe(tap(x => this.toastService.warning("File presenti nella cartella, impossibile eliminare.")));
    const deleteDir = (dir: AttachmentDirectoryDTO) => this.directoryService.delete(dir.id).pipe(catchError(e => {
        this.toastService.error("Errore durante la cancellazione della cartella")
        throw e;
      }),
      map(x => true)
    )
    this.attachmentService.count(exp).pipe(
      mergeMap(x => iif(() => x != 0, showError(), deleteDir(dir)))
    ).subscribe(x => {
      if (x) {
        this.toastService.success("Cartella '" + dir.name + "' eliminata");
        this.goBack();
      }
    })
  }

  applyCss(el: HTMLElement, event: DragEvent) {
    event.preventDefault()
    el.classList.add("dragOver");
  }

  removeCssClass(el: HTMLElement) {
    el.classList.remove("dragOver");

  }

  protected readonly faPlus = faPlus;

  openAddModal() {

  }

  protected readonly faSave = faSave;
  protected readonly event = event;

  placeholderFolder = 'Nuova cartella'
  clearPlaceholder(event: KeyboardEvent, dirName: HTMLDivElement) {
    if(dirName.innerText == this.placeholderFolder) {
      dirName.innerText = '';
    }
  }

  setPlaceholder(event: FocusEvent, dirName: HTMLDivElement) {

    if(dirName.innerText?.trim().length == 0) {
      dirName.innerText = this.placeholderFolder
    }
  }
}
