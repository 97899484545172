import {Component, forwardRef} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";
import {ReservationRequestDTO} from "../../service/dto";
import {currency} from "../../utils/utils";

type ChangeCallbackFn<T> = (value: T) => void;
type TouchCallbackFn = () => void;


@Component({
  selector: 'app-request-extra-data-form',
  templateUrl: './request-extra-data-form.component.html',
  styleUrl: './request-extra-data-form.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RequestExtraDataFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RequestExtraDataFormComponent),
      multi: true
    }
  ]
})
export class RequestExtraDataFormComponent implements ControlValueAccessor, Validator {
  form: FormGroup
  reservationRequest: ReservationRequestDTO;

  onTouched: () => void = () => {
  };


  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({


      // cashDeposit: undefined,
      // depositCurrency: 'EUR',
      nextHarbour: undefined,
      lastHarbour: undefined,

    });



  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }
    return {invalidForm: {valid: false, message: 'request extra data not valid'}};
  }

  writeValue(request: ReservationRequestDTO): void {
    this.reservationRequest = request;
    if (request) {

      this.form.patchValue(
        {
          nextHarbour: request.nextHarbour,
          lastHarbour: request.lastHarbour,
          // cashDeposit: request.cashDeposit,
          // depositCurrency: request.depositCurrency,

        });
    }
  }

  registerOnChange(fn: ChangeCallbackFn<object>): void {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: TouchCallbackFn): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }



  protected readonly currency = currency;
}

