import {Component, forwardRef} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {debounceTime, distinctUntilChanged, map, ReplaySubject, switchMap, tap} from "rxjs";
import {BoatTypeDTO, CountryDTO, ReservationRequestDTO} from "../../service/dto";
import moment from "moment";
import {BoatTypeService} from "../../service/boat-type.service";
import {BerthService} from "../../service/berth.service";
import {DockingTypeService} from "../../service/docking-type.service";
import {ToastrService} from "ngx-toastr";
import {ExpressionBuilder} from "../../service/ExpressionBuilder";
import {catchError} from "rxjs/operators";
import {CountryService} from "../../service/country.service";
import {currency} from "../../utils/utils";

type ChangeCallbackFn<T> = (value: T) => void;
type TouchCallbackFn = () => void;

@Component({
  selector: 'app-request-boat-info-form',
  templateUrl: './request-boat-info-form.component.html',
  styleUrl: './request-boat-info-form.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RequestBoatInfoFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RequestBoatInfoFormComponent),
      multi: true
    }
  ]
})
export class RequestBoatInfoFormComponent implements ControlValueAccessor, Validator {
  form: FormGroup
  boatTypes$ = new ReplaySubject<string>()
  boatTypesList: BoatTypeDTO[] = []
  reservationRequest: ReservationRequestDTO;
  countries$ = new ReplaySubject<string>()
  countryList: CountryDTO[];

  onTouched: () => void = () => {
  };


  constructor(private fb: FormBuilder,
              private boatTypeService: BoatTypeService,
              private countryService: CountryService,
              private berthService: BerthService,
              private dockingTypeService: DockingTypeService,
              private toastService: ToastrService,) {
    this.form = this.fb.group({
      id: undefined,
      plateNumber: undefined,
      length: [undefined, [Validators.required, Validators.min(0)]],
      beam: [undefined, [Validators.required, Validators.min(0)]],
      draft: undefined,
      boatName: undefined,
      type: undefined,
      flag: undefined,
      registryNumber: undefined,


    });

    this.boatTypes$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(x => {
        const exp = ExpressionBuilder.getBuilder().root();
        if (!x)
          return exp
        exp.ilike('name', '%' + x + '%')
        return exp
      }),
      switchMap(x => this.boatTypeService.getAll(0, 1000, x).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),
          map(x => x.data),
          tap(x => this.boatTypesList = x)
        )
      )
    ).subscribe();

    this.countries$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(x => {
        const exp = ExpressionBuilder.getBuilder().root();
        if (!x)
          return exp
        exp.ilike('name', '%' + x + '%')
        return exp
      }),
      switchMap(x => this.countryService.getAll(0, 1000, x).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),
          map(x => x.data),
          tap(x => this.countryList = x)
        )
      )
    ).subscribe();


  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }
    return {invalidForm: {valid: false, message: 'request not valid'}};
  }

  writeValue(request: ReservationRequestDTO): void {
    this.reservationRequest = request;
    if (request) {

      this.form.patchValue(
        {
          id: request.boatId,
          plateNumber: request.plateNumber,
          length: request.boatLength ?? request.length,
          beam: request.boatBeam ?? request.beam,
          draft: request.boatDraft?? request.draft,
          boatName: request.boatName,
          type: request.boatType,
          flag: request.flag,
          registryNumber: request.registryNumber,

        });
    }
  }

  registerOnChange(fn: ChangeCallbackFn<object>): void {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: TouchCallbackFn): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }


  protected readonly currency = currency;
  protected readonly Validators = Validators;

  isRequiredField(field: string) {
    const form_field = this.form.get(field);
    if (!form_field.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }
}
