import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {NgTemplateOutlet} from "@angular/common";
import {Subject} from "rxjs";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-search-in-place',
  standalone: true,
  imports: [
    NgSelectModule,
    FormsModule,
    MatIcon,
    MatIconButton,
    NgTemplateOutlet,
    MatTooltip
  ],
  templateUrl: './search-in-place.component.html',
  styleUrl: './search-in-place.component.scss'
})
export class SearchInPlaceComponent {


  private _open: boolean = false;
  @Input()
  placeholder!: string
  @Input()
  bindLabel!: string
  @Input()
  fieldLabel!: string
  @Input()
  searchObs!: Subject<any>
  @Input()
  tooltip!: string
  @Output()
  onUpdateData = new EventEmitter<any>();
  @Output()
  onOpenClose = new EventEmitter<string>();
  @Output()
  onClear = new EventEmitter<boolean>();
  private _data: any[]
  _selectedData: any;

  constructor() {
  }


  updateData() {
    this.onUpdateData.emit(this._selectedData);
  }

  protected readonly faSave = faSave;

  toggleOpen() {
    this._open = !this._open
    this.onOpenClose.emit(this._open ? 'OPEN' : 'CLOSE')
  }


  get data(): any[] {
    return this._data;
  }

  @Input()
  set data(value: any[]) {
    this._data = value;
  }

  get open(): boolean {
    return this._open;
  }
  @Input()
  set open(value: boolean) {
    this._open = value;
  }


  get selectedData(): any {

    return this._selectedData;
  }
  @Input()

  set selectedData(value: any) {

    this._selectedData = value;
  }

  onChangeData(event: Event) {
    // @ts-ignore
    console.log("event", event)
  }

  onClearData() {
    this.onClear.next(true)
  }
}
