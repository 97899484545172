import {Component, forwardRef} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator, Validators
} from "@angular/forms";
import {ShipOwnerDTO, SkipperDTO} from "../../service/dto";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-skipper-form',
  templateUrl: './skipper-form.component.html',
  styleUrl: './skipper-form.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SkipperFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SkipperFormComponent),
      multi: true
    }
  ]
})
export class SkipperFormComponent implements ControlValueAccessor, Validator {
  form: FormGroup
  skipper: SkipperDTO
  onTouched: () => void = () => {
  };

  constructor(private fb: FormBuilder,
              private toastService: ToastrService,) {
    this.form = this.fb.group({
      id: undefined,
      firstname: [undefined, Validators.required],
      lastname: [undefined, Validators.required],
      mobilePhone: undefined,
      email: [undefined, Validators.email],
    })
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);

  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;

  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }
    return {invalidForm: {valid: false, message: 'ship owner not valid'}};
  }

  writeValue(skipper: SkipperDTO): void {
    this.skipper = skipper;
    if (skipper) {

      this.form.patchValue(
        {
          id: skipper.id,
          firstname: skipper.firstname,
          lastname: skipper.lastname,
          mobilePhone: skipper.mobilePhone,
          email: skipper.email,
        });
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}
