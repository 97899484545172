import {Component, forwardRef} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator, Validators
} from "@angular/forms";
import {debounceTime, distinctUntilChanged, map, ReplaySubject, switchMap, tap} from "rxjs";
import {
  BerthDTO,
  BoatTypeDTO,
  CountryDTO,
  PortDTO,
  ReservationDetailDTO,
  ReservationDTO,
  ReservationRequestDTO
} from "../../service/dto";
import {BoatTypeService} from "../../service/boat-type.service";
import {CountryService} from "../../service/country.service";
import {BerthService} from "../../service/berth.service";
import {DockingTypeService} from "../../service/docking-type.service";
import {ToastrService} from "ngx-toastr";
import {ExpressionBuilder} from "../../service/ExpressionBuilder";
import {catchError} from "rxjs/operators";
import {PortService} from "../../service/port.service";
import {PortDashboardComponent} from "../../port/port-dashboard/port-dashboard.component";
import {currency, EURO, getCurrency, toNgbDate} from "../../utils/utils";
import {BoatService} from "../../service/boat.service";

type ChangeCallbackFn<T> = (value: T) => void;
type TouchCallbackFn = () => void;

@Component({
  selector: 'app-reservation-form',
  templateUrl: './reservation-form.component.html',
  styleUrl: './reservation-form.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReservationFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReservationFormComponent),
      multi: true
    }
  ]
})
export class ReservationFormComponent implements ControlValueAccessor, Validator{
  form: FormGroup
  berths$ = new ReplaySubject<string>()
  berthList: BerthDTO[] = []

  reservationDetail: ReservationDetailDTO
  boatList = []
  boat$ = new ReplaySubject<string>()

  onTouched: () => void = () => {
  };


  constructor(private fb: FormBuilder,
              private countryService: CountryService,
              private berthService: BerthService,
              private portService: PortService,
              private boatService: BoatService,
              private toastService: ToastrService,) {
    this.form = this.fb.group({
      boat: undefined,
      dates: {fromDate: undefined, toDates: undefined},
      price: undefined,
      currencyIso3: 'EUR',
      berth: undefined,
    });
    this.form.disable()

    this.boat$.pipe(
      map(x => {
        const exp = ExpressionBuilder.getBuilder().root()
        if (!x) {
          return exp
        }
        exp.ilike('name', '%' + x + '%')
        return exp
      }),
      switchMap(x => this.boatService.getAll(0, 1000, x).pipe(
        catchError(e => {
          this.toastService.error("Errore nel fetch dei dati")
          throw e
        }),
        map(x => x.data)
      ))
    ).subscribe(x => this.boatList = x)
    this.berths$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(x => {
        const exp = ExpressionBuilder.getBuilder().root();
        if (!x)
          return exp
        exp.ilike('name', '%' + x + '%')
        return exp
      }),
      switchMap(x => this.berthService.getAll(0, 5000, x).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),
          map(x => x.data),
          tap(x => this.berthList = x)
        )
      )
    ).subscribe();



  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }
    return {invalidForm: {valid: false, message: 'reservationDetail is not valid'}};
  }

  writeValue(request: ReservationDetailDTO): void {
    this.reservationDetail = request;
    if (request) {

      this.form.patchValue(
        {
          boat: request.parent.boat,
          dates: { fromDate: toNgbDate(request.fromDate), toDate: toNgbDate(request.toDate)},
          price: request.parent.price,
          currencyIso3: request.parent.currencyIso3 ?? 'EUR',
          berth: request.berth,
        });
    }
  }

  registerOnChange(fn: ChangeCallbackFn<object>): void {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: TouchCallbackFn): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }



  isRequiredField(field: string) {
    const form_field = this.form.get(field);
    if (!form_field?.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }

  protected readonly getCurrency = getCurrency;
  protected readonly currency = currency;
}
