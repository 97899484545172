import {Component, forwardRef} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {ShipOwnerDTO} from "../../service/dto";

@Component({
  selector: 'app-shipowner-form',
  templateUrl: './shipowner-form.component.html',
  styleUrl: './shipowner-form.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ShipownerFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ShipownerFormComponent),
      multi: true
    }
  ]
})
export class ShipownerFormComponent implements ControlValueAccessor, Validator {
  form: FormGroup
  shipOwner: ShipOwnerDTO
  onTouched: () => void = () => {
  };

  constructor(private fb: FormBuilder,
              private toastService: ToastrService,) {
    this.form = this.fb.group({
      id: undefined,
      firstname: [undefined, Validators.required],
      lastname: [undefined, Validators.required],
      vatCode: undefined,
      taxCode: undefined,
      phone: undefined,
      email: [undefined, Validators.email],
      pec: [undefined, Validators.email],
      type: 'PERSON'
    });

    this.form.get('type').valueChanges.subscribe(x => {
        if (x == 'COMPANY') {
          this.form.get('lastname').removeValidators(Validators.required)
          this.form.get('lastname').setValue(null)
          this.form.updateValueAndValidity()
        } else {
          this.form.get('lastname').setValidators([Validators.required])
          this.form.updateValueAndValidity()
        }
      }
    )
  }

  isPerson() {
    return this.form.getRawValue().type === 'PERSON';
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;

  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }
    return {invalidForm: {valid: false, message: 'ship owner not valid'}};
  }

  writeValue(shipOwner: ShipOwnerDTO): void {
    this.shipOwner = shipOwner;
    if (shipOwner) {

      this.form.patchValue(
        {
          id: shipOwner.id,
          firstname: shipOwner.firstname,
          lastname: shipOwner.lastname,
          vatCode: shipOwner.vatCode,
          taxCode: shipOwner.taxCode,
          mobilePhone: shipOwner.mobilePhone,
          phone: shipOwner.phone,
          email: shipOwner.email,
          pec: shipOwner.pec,
          type: shipOwner.type ?? 'PERSON'
        });
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

}
