import {Injectable, OnDestroy} from '@angular/core';
import {combineLatest, filter, map, Observable, Subject, takeUntil} from "rxjs";

import {Apollo, gql, MutationResult} from "apollo-angular";
import {BoolOperation, ExpressionBuilder} from "./ExpressionBuilder";
import {AttachmentDTO, AttachmentType} from "./dto";
import {BERTH_FIELD} from "./berth.service";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {environment} from "../../environments/environment";
import moment from "moment";
import {print} from 'graphql/language/printer'

export const ATTACHMENT_FIELD = gql`
  fragment ATTACHMENT_FIELD on AttachmentDTO {
    id
    name
    description
    expiration
    type
    mimeType
    contentLength
    url
    directoryId
    createdAt
  }
`;


const add = gql`
  ${ATTACHMENT_FIELD}

  mutation AddAttachment($dto: AttachmentInput!) {
    attachment_add_one(body: $dto) {
      ...ATTACHMENT_FIELD
    }
  }`
const update = gql`
  ${ATTACHMENT_FIELD}

  mutation UpdateAttachment($id: ID!, $dto: AttachmentInput!) {
    attachment_update_one(id: $id, body: $dto){
      ...ATTACHMENT_FIELD

    }

  }`

const del = gql`

  mutation DeleteAttachment($id: ID!) {
    attachment_delete_one(id: $id) {
      id
    }

  }`

const getAll = gql`
  ${ATTACHMENT_FIELD}

  query GetAllAttachments($pageNumber: Int, $pageDim: Int, $where:String ) {
    attachment(pageNum: $pageNumber, pageDim: $pageDim, where: $where, sort: "id,desc") {
      ...ATTACHMENT_FIELD

    }
  }
`;

const getById = gql`
  ${ATTACHMENT_FIELD}
  ${BERTH_FIELD}

  query GetAttachmentById($id: ID!, $includeBerths: Boolean = false) {
    attachment_by_pk(id: $id) {
      ...ATTACHMENT_FIELD
      berths @include(if: $includeBerths) {
        ...BERTH_FIELD
      }
    }
  }`;


const count = gql`query CountAttachment( $where: String ) {
  attachment_aggregate(aggregation: {count: {field: "id", distinct: false}}, where: $where) {
    count
  }
}`;

const upload = gql`
  ${ATTACHMENT_FIELD}

  mutation uploadFile($input: Upload!, $externalId: Int!, $directoryId: Int, $type: String, $expiration: DateTime, $description: String) {
    uploadFile(input: $input, directoryId:$directoryId, externalId:$externalId , type: $type, expiration: $expiration, description: $description){
      ...ATTACHMENT_FIELD
      __typename
    }

  }`


@Injectable({
  providedIn: 'root'
})
export class AttachmentService implements OnDestroy {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apollo: Apollo, private http: HttpClient) {

  }

  getAll(page: number = 0, pageSize: number = 10, filters?: BoolOperation, sort?: string): Observable<{
    data: AttachmentDTO[],
    totalRows: number
  }> {

    const fetchData = this.apollo
      .watchQuery({
        query: getAll,
        variables: {
          pageNumber: page,
          pageDim: pageSize,
          where: ExpressionBuilder.toGql(filters),
        },
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    const fetchCount = this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    return combineLatest([fetchData, fetchCount]).pipe(
      takeUntil(this.destroy$),
      filter(x => !!x[0].data),
      map(x => {
        // @ts-ignore
        const aggRes = x[1].data['attachment_aggregate']
        // @ts-ignore
        const data = x[0].data?.['attachment']
        const d = data.map((y: any) => (({__typename, ...o}) => o)(y));
        return {
          data: d,
          totalRows: aggRes.count
        }
      })
    )
  }

  count(filters?: BoolOperation): Observable<number> {
    return this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(
        filter(c => !c.loading),
        map(x => {
            // @ts-ignore
            const aggRes = x.data['attachment_aggregate']
            return aggRes.count
          }
        )
      )
  }


  getById(id: number, includes: { berths: boolean } = {berths: false}): Observable<AttachmentDTO> {

    const inc = {...{berths: false}, ...includes}
    return this.apollo.watchQuery({
      query: getById,
      variables: {
        id: id,
        includeBerths: inc.berths
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      filter(x => !x.loading),
      takeUntil(this.destroy$),
      filter(x => !x.loading),
      map(x => {
          // @ts-ignore
          const data = x.data?.['attachment_by_pk'];
          const d = (({__typename, ...o}) => o)(data)
          return d
        }
      )
    );
  }

  //

  add(dto: AttachmentDTO): Observable<AttachmentDTO> {
    return this.apollo.mutate({
      mutation: add,
      variables: {dto: dto},
      fetchPolicy: 'no-cache',
    }).pipe(map((x: MutationResult) => x.data.insert_attachment_one))
  }

  update(id: number, dto: AttachmentDTO): Observable<AttachmentDTO> {
    return this.apollo.mutate({
      mutation: update,
      variables: {id: id, dto: dto},
      fetchPolicy: 'no-cache',

    }).pipe(map((x: MutationResult) => x.data.update_attachment_by_pk))
  }

  delete(id: number): Observable<AttachmentDTO> {
    return this.apollo.mutate({
      mutation: del,
      variables: {id: id},
      fetchPolicy: 'no-cache',

    }).pipe(map((x: MutationResult) => x.data.attachment_delete_one))
  }

  uploadFile(file: File, type: AttachmentType = AttachmentType.GENERIC, directoryId?: number, externalId?: number, description?: string, expiration: Date = null): Observable<AttachmentDTO> {
    let headers = new HttpHeaders()
    headers = headers.append('apollo-require-preflight', true + '')
    let formData = new FormData();
    let vars = {
      0: ["variables.input"],

    }
    const body = {
      operationName: 'uploadFile',
      query: print(upload),
      variables: {
        input: null,
        type: type,
        directoryId: directoryId,
        expiration: expiration ? moment(expiration).toISOString() : null,
        externalId: externalId,
        description: description,
      }
    }
    formData.append('operations', JSON.stringify(body))
    formData.append('map', JSON.stringify(vars))
    formData.append('0', file)


    return this.http.post(environment.graphql_endpoint, formData, {
      headers: headers
    }).pipe(
      map((x: MutationResult) => {
        if(x.errors){
          throw Error('upload filed')
        }
        return x.data.update_attachment_by_pk
      })
    )
    // return this.apollo.mutate({
    //   mutation: upload,
    //   variables: {
    //     input: null,
    //     type: type,
    //     expiration: expiration
    //   },
    //   fetchPolicy: 'no-cache',
    //
    // }).pipe(map((x: MutationResult) => x.data.update_attachment_by_pk))
  }


  downloadFile(attachment: AttachmentDTO) {
    let headers = new HttpHeaders()
    return this.http.get<HttpResponse<Blob>>(attachment.url, {
      headers: headers,
      observe: 'response'
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
