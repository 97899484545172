import {Component, EventEmitter, forwardRef, inject, Input, Output} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'app-ngb-datepicker-range',
  standalone: true,
  templateUrl: './ngb-datepicker-range.component.html',
  imports: [NgbDatepickerModule, FormsModule, MatIconModule],
  styleUrl: './ngb-datepicker-range.component.scss',
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NgbDatepickerRangeComponent), multi: true}]
})
export class NgbDatepickerRangeComponent implements ControlValueAccessor {
  calendar = inject(NgbCalendar);
  formatter = inject(NgbDateParserFormatter);

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  @Input()
  minDate: NgbDate
  @Input()
  maxDate: NgbDate
  @Output()
  onDateChange = new EventEmitter<[Date, Date]>();
  disabed: boolean = false;

  constructor() {

  }

  private onChange: (value: any) => void;
  private onTouched: () => void;

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    let fromDate = null
    let toDate = null
    if (this.fromDate)
      fromDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)
    if (this.toDate)
      toDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day)
    this.onDateChange.emit([fromDate, toDate])
    if (this.fromDate && this.toDate) {
      this.onChange({fromDate: this.fromDate, toDate: this.toDate});
    }
    this.onTouched();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }


  writeValue(value: any): void {
    if (value) {
      this.fromDate = value.fromDate;
      this.toDate = value.toDate;
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabed = isDisabled
  }
}
