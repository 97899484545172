import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Directive({
  selector: '[appTranslatePlaceholder]'
})
export class TranslatePlaceholderDirective implements OnInit {
  @Input('appTranslatePlaceholder') placeholderKey: string;

  constructor(private el: ElementRef, private translate: TranslateService) {
  }

  ngOnInit() {
    this.translate.get(this.placeholderKey).subscribe((translatedText: string) => {
      this.el.nativeElement.placeholder = translatedText;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get(this.placeholderKey).subscribe((translatedText: string) => {
        this.el.nativeElement.placeholder = translatedText;
      });
    });
  }
}
