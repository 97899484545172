
<div [formGroup]="form" class="space-y-3">

  <div class="grid grid-cols-4 gap-3">

    <div>
      <div class="flex flex-col relative">
        <label>Scalo precedente</label>
        <input class="pl-3 pr-10 py-1 text-xs w-full placeholder:text-gray-500 form-control border rounded-lg"
               formControlName="lastHarbour" type="text"/>

      </div>
    </div>
    <div>
      <div class="flex flex-col relative">
        <label>Scalo successivo</label>
        <input class="pl-3 pr-10 py-1 text-xs w-full placeholder:text-gray-500 form-control border rounded-lg"
               formControlName="nextHarbour" type="text"/>

      </div>
    </div>

    <!--    <div>-->
    <!--      <div class="flex flex-col">-->
    <!--        <label>Deposito cauzionale</label>-->
    <!--        <div class="relative">-->
    <!--          <input class="pl-4 py-1 text-xs w-full placeholder:text-gray-500 form-control border rounded-lg "-->
    <!--                 style="padding-right: 2.8rem"-->
    <!--                 formControlName="cashDeposit" type="number" min="0.00" step="1.00"/>-->
    <!--          <div [matMenuTriggerFor]="menu"-->
    <!--               class="absolute top-1 right-3 pl-2 pr-3 cursor-pointer border rounded-lg bg-gray-100">-->
    <!--            <span class="font-bold text-sm">{{ getCurrency() }}</span>-->
    <!--          </div>-->
    <!--          <mat-menu #menu="matMenu">-->
    <!--            <button class="flex space-x-2" mat-menu-item [matTooltip]="cur.name" matTooltipShowDelay="1000"-->
    <!--                    matTooltipPosition="left" (click)="form.get('depositCurrency').setValue(cur.iso3)"-->
    <!--                    *ngFor="let cur of currency">-->
    <!--              <span class="text-sm">{{ cur.symbol }}</span>-->
    <!--            </button>-->

    <!--          </mat-menu>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</div>
